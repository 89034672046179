















































// @ is an alias to /src
import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import { groupBy } from 'lodash';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import {
  IFootballPlayer,
  IFootballStatistics,
  IMatch,
  IPlayer,
  IShortLong,
  ITeamStats,
} from '@/types/teams';
import { loadSeo } from '@/utils/pages';
import { IIndexable, ISeo } from '@/types/pages';
import TeamMenu from '@/components/TeamMenu.vue';
import Team, { IGroupedPlayers } from '@/components/Team.vue';
import TournamentTable from '@/components/TournamentTable.vue';
import { getSeason, getSeasonString } from '@/utils/team';

const AllQuery = `
  query {
    players: footballPlayers {
      id,
      number,
      position,
      unitFull,
      history {
        id,
        dateStart,
        dateEnd,
        student,
        team {
          avatar,
          name,
          fullName,
          city,
        }
      },
      person {
        id,
        firstName,
        lastName,
        avatar,
        birthday,
        weight,
        height,
      },
      statistics {
        id,
        season,
        matches,
        totalGoals,
        passes,
        redCards,
        yellowCards,
      },
    },
  }
`;

@Component({
  name: 'footballStat',
  components: {
    Team,
    TeamMenu,
    News,
    Footer,
    Afisha,
    LayoutBlock,
    TournamentTable,
    Header,
  },
})
export default class FootballPersonalStat extends Vue {
  private stats: ITeamStats[] = [];
  private get seasonString() {
    return getSeasonString();
  }
  private players: IGroupedPlayers = {};
  private matches: IMatch[] = [];
  private titleMap = {
    FORWARD: 'Нападающие',
    DEFENDER: 'Защитники',
    MID: 'Полузащитники',
  };
  private statMap = {
    BOMB: 'Бомбардиры',
    ASSIST: 'Ассистенты',
    GOALS_PASSES: 'Гол+пас',
    RENEGADES: 'Нарушители',
  };
  private statPositionMap = {
    FORWARD: this.statMap,
    DEFENDER: this.statMap,
    MID: this.statMap,
  };
  private scoreMap: {[key: string]: IShortLong} = {
    matches: {short: 'м', long: 'матчи'},
    totalGoals: {short: 'г', long: 'голы'},
    passes: {short: 'п', long: 'передачи'},
    goalsPasses: {short: 'г+п', long: 'гол+пас'},
    yellowCards: {short: 'жк', long: 'желтые карточки'},
    redCards: {short: 'кк', long: 'красные карточки'},
  };
  private scorePositionMap = {
    FORWARD: this.scoreMap,
    DEFENDER: this.scoreMap,
    MID: this.scoreMap,
  };
  private async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: gql(AllQuery),
    });
    if (data) {
      this.players = groupBy(data.players, 'position') as IGroupedPlayers;
      delete this.players.GOALKEEPER;
    }
    loadSeo({title: 'СК "Держава" ТГУ — Футбол | Личная статистика', keywords: '', description: ''} as ISeo);
  }
  private getScore = (stat: ITeamStats, key: string) => {
    // if (key === 'goalsDiff') {
    //
    // }
    return stat && stat[key as keyof ITeamStats] || 0;
  }

  private getPlaceClassName(place: number) {
    return ['b-tournament__place', place < 1 && 'b-tournament__place--first'].filter(Boolean);
  }

  private getFullName({person: {firstName, lastName}}: IFootballPlayer) {
    return [firstName, lastName].join(' ');
  }

  private getCurrentStat = (player: IFootballPlayer, season: string) => {
    const result = player.statistics.find((stat) => stat.season === season);
    if (result) {
      result.allPoints = result.totalGoals + result.passes;
      result.goalsPasses = result.totalGoals + result.passes;
      result.allCards = (result.redCards * 2) + result.yellowCards;
    }

    return result;
  }

  private getStat = (key: string, players: IFootballPlayer[]) => {
    const season = `A_${getSeason()}`;
    const result = [...players.map((player) => ({...player, currentStat: this.getCurrentStat(player, season)}))];
    const keyMap: IIndexable = {
      BOMB: 'totalGoals',
      ASSIST: 'passes',
      GOALS_PASSES: 'goalsPasses',
      RENEGADES: 'allCards',
    };
    const currentPoints = keyMap[key] as keyof IFootballStatistics;
    result.sort((a, b) => {
      if (!a.currentStat || !b.currentStat) {
        return -1;
      }
      return Number(b.currentStat[currentPoints]) - Number(a.currentStat[currentPoints]);
    });
    // }

    return result;
  }

  private getPlayerLink(player: IPlayer) {
    return `/football/sostav/player/${player.id}/`;
  }
}
