









// @ is an alias to /src
import { Component, Vue } from 'vue-property-decorator';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import { loadSeo } from '@/utils/pages';
import { ISeo } from '@/types/pages';
import TeamMenu from '@/components/TeamMenu.vue';
import Team from '@/components/Team.vue';
import { INewsItem } from '@/types/news';

@Component({
  name: 'volleyballMTeam',
  components: {
    Team,
    TeamMenu,
    News,
    Footer,
    Afisha,
    LayoutBlock,
    Header,
  },
})
export default class VolleyballMTeam extends Vue {
  private news: INewsItem[] = [];
  private titleMap = {
    FORWARD: 'Нападающие',
    LIBERO: 'Либеро',
    PASSING: 'Пасующие',
  };
  private data() {
    return {
      name: '',
    };
  }
  private async mounted() {
    window.scroll(0, 0);
    loadSeo({title: 'СК "Держава" ТГУ — Волейбол (Мужчины) | Состав', keywords: '', description: ''} as ISeo);
  }
}
