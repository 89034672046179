

















































import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import { IMediaAlbum, IMediaImage, ISeo } from '@/types/pages';
import LayoutBlock from '@/components/LayoutBlock.vue';
import { loadSeo } from '@/utils/pages';

const mediaQuery = `
  query {
    album(id: :id) {
      id,
      sportType,
      date,
      title,
      images {
        id,
        thumb,
        image,
        title,
      },
    },
  },
`;

@Component({
  components: {
    LayoutBlock,
  },
})
export default class MediaAlbum extends Vue {
  private album: IMediaAlbum | null = null;
  private visible: boolean = false;
  private imgs: string = '';
  private index: number = 0;

  private async mounted() {
    window.scroll(0, 0);
    const { id } = this.$route.params;
    const { data } = await this.$apollo.query({
      query: gql(mediaQuery.replace(':id', id)),
    });
    if (data.album) {
      this.album = data.album;
      this.imgs = data.album.images.reduce((acc: string, img: IMediaImage) => acc.concat(img.image), '');
      const title = data.album.title;
      if (title) {
        loadSeo({title: `СК "Держава" ТГУ — Медиа ${title}`, keywords: '', description: ''} as ISeo);
      }
    }
  }
  private handleHide() {
    this.imgs = '';
    this.visible = false;
  }
  private get imagesLength() {
    if (!this.album || !Array.isArray(this.album.images)) {
      return 0;
    }
    return this.album.images.length;
  }
  private get nextIndex() {
    return this.imagesLength;
  }
  private getImageIndex(img: string): number {
    if (!this.album || !Array.isArray(this.album.images)) {
      return -1;
    }

    return this.album.images.findIndex(({image}) => image === img);
  }
  private getImageByIndex(index: number): string {
    if (!this.album || !Array.isArray(this.album.images)) {
      return '';
    }
    const imagesLength = this.imagesLength;
    const newIndex = index < 0 ? imagesLength - 1 : index % imagesLength;
    const result = this.album.images[newIndex];

    return result && result.image || '';
  }
  private handleNext() {
    const currentIndex = this.getImageIndex(this.imgs);

    this.setImg(currentIndex + 1);
  }
  private setImg(index: number) {
    const image = this.getImageByIndex(index);
    if (image) {
      this.imgs = image;
      this.visible = true;
    } else {
      this.visible = false;
      this.imgs = '';
    }
  }
  private handlePrev() {
    const currentIndex = this.getImageIndex(this.imgs);

    this.setImg(currentIndex - 1);
  }
  private openGallery(index: number) {
    if (!this.album) {
      return;
    }
    // this.index = index;
    const { image } = this.album.images[index];
    this.imgs = image;
    this.visible = true;
  }
}
