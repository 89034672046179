






























































  import { Component, Prop, Vue } from 'vue-property-decorator';
  import gql from 'graphql-tag';
  import { groupBy } from 'lodash';
  import LayoutBlock from '@/components/LayoutBlock.vue';
  import { IPerson, IPlayer, IStaff } from '@/types/teams';
  import { pluralWithNumber } from '@/utils/text';

  const AllQuery = `
  query {
    players: :players {
      id,
      number,
      position,
      unit,
      person {
        id,
        firstName,
        lastName,
        avatar,
        birthday,
      },
    },
    staff(types: ":type") {
      id,
      startTime,
      sportType,
      text,
      type,
      position,
      person {
        id,
        firstName,
        lastName,
        middleName,
        avatar,
        birthday,
        weight,
        height,
      },
    },
  }
`;

  export interface IGroupedPlayers {
    [key: string]: IPlayer[];
  }

  export interface IGroupedStaff {
    [key: string]: IStaff[];
  }

  @Component({
    name: 'team',
    components: {
      LayoutBlock,
    },
  })
  export default class Team extends Vue {
    @Prop() private type!: string;
    @Prop() private types!: string[];
    @Prop() private customTitleMap!: string[];
    private players: IGroupedPlayers = {};
    private staff: IGroupedStaff = {};
    private tabMap = {
      PLAYERS: 'Игроки',
      TRAINER: 'Тренеры',
      ADMIN: 'Администрация',
    };
    private titleMap = {
      ...this.customTitleMap,
      ...this.tabMap,
    };
    private data() {
      return {
        name: '',
      };
    }
    private async mounted() {
      const { data } = await this.$apollo.query({
        query: gql(AllQuery
          .replace(':players', `${this.type.replace('-f', 'F').replace('-m', 'M')}Players`)
          .replace(':type', this.type.replace(/-/g, '_')),
        ),
      });
      if (data) {
        this.players = groupBy(data.players, 'position') as IGroupedPlayers;
        this.staff = groupBy(data.staff, 'type') as IGroupedStaff;
      }
    }
    private get groupedPlayers() {
      return groupBy(this.players, 'position');
    }
    private getFullName({firstName, lastName}: IPerson) {
      return [firstName, lastName].join(' ');
    }
    private getFullNameContent({firstName, lastName}: IPerson) {
      return [firstName, lastName].join(' <br />');
    }
    private getFullStaffName({firstName, lastName, middleName}: IPerson) {
      return [lastName, firstName, middleName].join(' ');
    }
    private getAge = (birthDate: string) => {
      const diff = (Number(new Date()) - new Date(birthDate).getTime()) / 3.15576e+10 ;

      return Math.floor(diff);
    }

    private getFullAge = (birthDate: string) => {
      const age = this.getAge(birthDate);

      return pluralWithNumber(age, ['год', 'года', 'лет']);
    }
    private getPlayerLink(player: IPlayer) {
      return `/${this.type}/sostav/player/${player.id}/`;
    }
    private getStaffLink(staff: IStaff) {
      return `/${this.type}/sostav/staff/${staff.id}/`;
    }
    private getGroups() {
      const result: any = {};
      if (Object.keys(this.players).length) {
        const key = 'PLAYERS';
        result[key] = {...this.players};
      }
      if (this.staff) {
        Object.keys(this.staff).forEach((key) => {
          result[key] = {[key]: this.staff[key]};
        });
      }

      return result;
    }
  }
