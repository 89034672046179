
























































// @ is an alias to /src
import { Component, Prop, Vue } from 'vue-property-decorator';
import { groupBy } from 'lodash';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import { IHockeyPlayer, IHockeyStatistics, ICommonStat, IPlayer, IShortLong, ITeamStats } from '@/types/teams';
import { IIndexable, ISeo } from '@/types/pages';
import TeamMenu from '@/components/TeamMenu.vue';
import Team, { IGroupedPlayers } from '@/components/Team.vue';
import TournamentTable from '@/components/TournamentTable.vue';
import { getSeason } from '@/utils/team';

const defaultScoreMap: {[key: string]: IShortLong} = {
  totalGoals: {short: 'ш', long: 'всего забито'},
  totalPass: {short: 'а', long: 'всего передач'},
  penalty: {short: 'шм', long: 'штраф (мин.)'},
};
const defaultScoreMapGoalkeeper: {[key: string]: IShortLong} = {
  totalGames: {short: 'и', long: 'игр'},
  missedGoals: {short: 'ш', long: 'пропущено'},
  allWins: {short: 'п', long: 'побед'},
};

@Component({
  name: 'leaders',
  components: {
    Team,
    TeamMenu,
    News,
    Footer,
    Afisha,
    LayoutBlock,
    TournamentTable,
    Header,
  },
})
export default class Leaders<T extends IPlayer> extends Vue {
  @Prop() private players!: T[];
  @Prop() private type!: string;
  @Prop({default: {
    BOMB: 'allPoints',
    SNIPER: 'totalGoals',
    ASSIST: 'totalPass',
    GOALKEEPER: 'allWins',
  }}) private keyMap!: IIndexable;
  @Prop({default: {
    FORWARD: 'Нападающий',
    DEFENDER: 'Защитник',
    GOALKEEPER: 'Вратарь',
  }}) private positionMap!: IIndexable;
  @Prop({default: {
    BOMB: 'Бомбардиры',
    SNIPER: 'Снайперы',
    ASSIST: 'Ассистенты',
    GOALKEEPER: 'Вратари (КН)',
  }}) private statMap!: IIndexable;
  @Prop({default: {
    FORWARD: defaultScoreMap,
    DEFENDER: defaultScoreMap,
    GOALKEEPER: defaultScoreMapGoalkeeper,
  }}) private scorePositionMap!: {[key: string]: {[key: string]: IShortLong}};
  @Prop() private updateCurrentStat!: (stats: ICommonStat) => ICommonStat;

  @Prop() private getScores!: (stats: ICommonStat, type: string) => string;
  private groupedPlayers = (players: T[]) => {
    return  groupBy(players, 'position') as IGroupedPlayers;
  }
  private getScore = (stat: ITeamStats | void, key: string) => {
    return stat && stat[key as keyof ITeamStats] || 0;
  }

  private getFullName({person: {firstName, lastName}}: IPlayer) {
    return [firstName, lastName].join(' ');
  }

  private getCurrentStat = (player: IHockeyPlayer, season: string) => {
    const result = player.statistics.find((stat) => stat.season === season);

    return this.updateCurrentStat && result
      ? this.updateCurrentStat(result as unknown as ICommonStat) as unknown as IHockeyStatistics
      : result;
  }

  private getStat = (key: string, players: IHockeyPlayer[]) => {
    const season = `A_${getSeason()}`;
    const result = players.filter((player) => key === 'GOALKEEPER'
      ? player.position === key
      : player.position !== 'GOALKEEPER',
    )
      .map((player) => ({...player, currentStat: this.getCurrentStat(player, season)}));
    const currentPoints = this.keyMap[key] as keyof IHockeyStatistics;
    result.sort((a, b) => {
      if (!a.currentStat || !b.currentStat) {
        return -1;
      }
      return Number(b.currentStat[currentPoints]) - Number(a.currentStat[currentPoints]);
    });

    return result;
  }
  private getPlayerLink(player: IPlayer) {
    return `/${this.type}/sostav/player/${player.id}/`;
  }
}
