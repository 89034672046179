import Vue from 'vue';
import Router from 'vue-router';
import Home from './views/Home.vue';
import Club from './views/Club.vue';
import NewsList from './views/NewsList.vue';
import NewsItem from './views/NewsItem.vue';
import Infrastructure from './views/Infrastructure.vue';
import History from './views/History.vue';
import Hockey from './views/Hockey.vue';
import HockeyTeam from './views/HockeyTeam.vue';
import HockeyPlayer from './views/HockeyPlayer.vue';
import StaffPage from './views/StaffPage.vue';
import Sections from './views/Sections.vue';
import NewsListSpartakiada from './views/NewsListSpartakiada.vue';
import Spartakiada from './views/Spartakiada.vue';
import SpartakiadaItem from './views/SpartakiadaItem.vue';
import SpartakiadaCalendar from './views/SpartakiadaCalendar.vue';
import TeamHistory from '@/views/TeamHistory.vue';
import MediaPage from '@/views/MediaPage.vue';
import Football from '@/views/Football.vue';
import FootballTeam from '@/views/FootballTeam.vue';
import Basketball from '@/views/Basketball.vue';
import BasketballTeam from '@/views/BasketballTeam.vue';
import BasketballFTeam from '@/views/BasketballFTeam.vue';
import BasketballF from '@/views/BasketballF.vue';
import FootballPlayer from '@/views/FootballPlayer.vue';
import BasketballPlayer from '@/views/BasketballPlayer.vue';
import HockeyStat from '@/views/HockeyStat.vue';
import HockeyPersonalStat from '@/views/HockeyPersonalStat.vue';
import MediaAlbumPage from '@/views/MediaAlbumPage.vue';
import FootballStat from '@/views/FootballStat.vue';
import BasketballFStat from '@/views/BasketballFStat.vue';
import BasketballMStat from '@/views/BasketballMStat.vue';
import BasketballMPersonalStat from '@/views/BasketballMPersonalStat.vue';
import FootballPersonalStat from '@/views/FootballPersonalStat.vue';
import BasketballFPersonalStat from '@/views/BasketballFPersonalStat.vue';
import VolleyballM from '@/views/VolleyballM.vue';
import VolleyballMTeam from '@/views/VolleyballMTeam.vue';
import VolleyballFTeam from '@/views/VolleyballFTeam.vue';
import VolleyballF from '@/views/VolleyballF.vue';
import VolleyballPlayer from '@/views/VolleyballPlayer.vue';
import Pedestal from '@/views/Pedestal.vue';
import Pride from '@/views/Pride.vue';
import PridePerson from '@/views/PridePerson.vue';
import Search from '@/views/Search.vue';
import Results from '@/views/Results.vue';

Vue.use(Router);

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home,
    },
    {
      path: '/news/:path',
      name: 'newsItem',
      component: NewsItem,
    },
    {
      path: '/news/',
      name: 'news',
      component: NewsList,
    },
    {
      path: '/sportivnaya-infrastruktura/',
      name: 'infrastructure',
      component: Infrastructure,
    },
    {
      path: '/istoriya/',
      name: 'history',
      component: History,
    },
    {
      path: '/football/',
      name: 'football',
      component: Football,
    },
    {
      path: '/football/sostav/player/:id/',
      name: 'footballPlayer',
      component: FootballPlayer,
    },
    {
      path: '/football/sostav/staff/:id/',
      name: 'staffPage',
      component: StaffPage,
    },
    {
      path: '/football/sostav/',
      name: 'footballTeam',
      component: FootballTeam,
    },
    {
      path: '/football/tablica/',
      name: 'footballStat',
      component: FootballStat,
    },
    {
      path: '/basketball-m/',
      name: 'basketballM',
      component: Basketball,
    },
    {
      path: '/basketball-m/sostav/',
      name: 'basketballTeam',
      component: BasketballTeam,
    },
    {
      path: '/basketball-m/sostav/player/:id/',
      name: 'basketballPlayerM',
      component: BasketballPlayer,
    },
    {
      path: '/basketball-m/sostav/staff/:id/',
      name: 'staffPageBasketballM',
      component: StaffPage,
    },
    {
      path: '/basketball-f/',
      name: 'basketballF',
      component: BasketballF,
    },
    {
      path: '/basketball-f/sostav/',
      name: 'basketballFTeam',
      component: BasketballFTeam,
    },
    {
      path: '/basketball-f/sostav/player/:id/',
      name: 'basketballPlayerF',
      component: BasketballPlayer,
    },
    {
      path: '/basketball-f/sostav/staff/:id/',
      name: 'staffPageBasketballF',
      component: StaffPage,
    },
    {
      path: '/hockey/',
      name: 'hockey',
      component: Hockey,
    },
    {
      path: '/hockey/sostav/player/:id/',
      name: 'hockeyPlayer',
      component: HockeyPlayer,
    },
    {
      path: '/hockey/sostav/staff/:id/',
      name: 'staffPage',
      component: StaffPage,
    },
    {
      path: '/hockey/sostav/',
      name: 'hockeyTeam',
      component: HockeyTeam,
    },
    {
      path: '/hockey/statistica/',
      name: 'hockeyPersonalStat',
      component: HockeyPersonalStat,
    },
    {
      path: '/spartakiada/calendar/',
      name: 'spartakiadaCalendar',
      component: SpartakiadaCalendar,
    },
    {
      path: '/spartakiada/',
      name: 'spartakiada',
      component: Spartakiada,
    },
    {
      path: '/spartakiada/news/',
      name: 'spartakiadaNews',
      component: NewsListSpartakiada,
    },
    {
      path: '/spartakiada/:path',
      name: 'spartakiadaItem',
      component: SpartakiadaItem,
    },
    {
      path: '/sections/',
      name: 'sections',
      component: Sections,
    },
    {
      path: '/hockey/tablica/',
      name: 'hockeyStat',
      component: HockeyStat,
    },
    {
      path: '/hockey/:path/',
      name: 'teamHistory',
      component: TeamHistory,
    },
    {
      path: '/football/statistica/',
      name: 'footballPersonalStat',
      component: FootballPersonalStat,
    },
    {
      path: '/football/:path/',
      name: 'teamHistory',
      component: TeamHistory,
    },
    {
      path: '/basketball-f/tablica/',
      name: 'basketballFStat',
      component: BasketballFStat,
    },
    {
      path: '/basketball-f/statistica/',
      name: 'basketballFPersonalStat',
      component: BasketballFPersonalStat,
    },
    {
      path: '/basketball-f/:path/',
      name: 'teamHistory',
      component: TeamHistory,
    },
    {
      path: '/basketball-m/tablica/',
      name: 'basketballMStat',
      component: BasketballMStat,
    },
    {
      path: '/basketball-m/statistica/',
      name: 'basketballMPersonalStat',
      component: BasketballMPersonalStat,
    },
    {
      path: '/basketball-m/:path/',
      name: 'teamHistory',
      component: TeamHistory,
    },
    {
      path: '/volleyball-f/sostav/staff/:id/',
      name: 'staffPage',
      component: StaffPage,
    },
    {
      path: '/volleyball-f/',
      name: 'volleyballF',
      component: VolleyballF,
    },
    {
      path: '/volleyball-f/sostav/',
      name: 'volleyballFTeam',
      component: VolleyballFTeam,
    },
    {
      path: '/volleyball-f/sostav/player/:id/',
      name: 'volleyballPlayerF',
      component: VolleyballPlayer,
    },
    {
      path: '/volleyball-f/:path/',
      name: 'teamHistory',
      component: TeamHistory,
    },
    {
      path: '/volleyball-m/sostav/staff/:id/',
      name: 'staffPage',
      component: StaffPage,
    },
    {
      path: '/volleyball-m/',
      name: 'volleyballM',
      component: VolleyballM,
    },
    {
      path: '/volleyball-m/sostav/',
      name: 'volleyballMTeam',
      component: VolleyballMTeam,
    },
    {
      path: '/volleyball-m/sostav/player/:id/',
      name: 'volleyballPlayerM',
      component: VolleyballPlayer,
    },
    {
      path: '/volleyball-m/:path/',
      name: 'teamHistory',
      component: TeamHistory,
    },
    {
      path: '/photo-video/:id/',
      name: 'mediaPage',
      component: MediaAlbumPage,
    },
    {
      path: '/photo-video/',
      name: 'mediaPage',
      component: MediaPage,
    },
    {
      path: '/pedestal/',
      name: 'pedestal',
      component: Pedestal,
    },
    {
      path: '/search/',
      name: 'search',
      component: Search,
    },
    {
      path: '/nasha-gordost/',
      name: 'pride',
      component: Pride,
    },
    {
      path: '/nasha-gordost/:id',
      name: 'pridePerson',
      component: PridePerson,
    },
    {
      path: '/results/:path',
      name: 'results',
      component: Results,
    },
    {
      path: '/club/',
      name: 'club',
      // route level code-splitting
      // this generates a separate chunk (about.[hash].js) for this route
      // which is lazy-loaded when the route is visited.
      // component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
      component: Club,
    },
  ],
});
