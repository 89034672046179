













import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Footer from '@/components/Footer.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { loadSeo } from '@/utils/pages';
import { ISeo } from '@/types/pages';
import MediaAlbums from '@/components/MediaAlbums.vue';

@Component({
  name: 'mediaPage',
  components: {
    Footer,
    LayoutBlock,
    Header,
    MediaAlbums,
  },
})

export default class MediaPage extends Vue {
  @Prop() private link!: string;
  private async mounted() {
    window.scroll(0, 0);
    loadSeo({title: `СК "Держава" ТГУ — Медиа`, keywords: '', description: ''} as ISeo);
  }
}
