













import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Footer from '@/components/Footer.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import MediaAlbum from '@/components/MediaAlbum.vue';

@Component({
  name: 'mediaPage',
  components: {
    Footer,
    LayoutBlock,
    Header,
    MediaAlbum,
  },
})

export default class MediaAlbumPage extends Vue {
  @Prop() private link!: string;
  private async mounted() {
    window.scroll(0, 0);
  }
}
