import { IPage, IPageContent, IPageImage, ISeo } from '@/types/pages';

export const loadSeo = (seo: ISeo) => {
  if (seo.title) {
    document.title = seo.title;
  }
  const descriptionTag = document.querySelector('meta[name="description"]');
  if (descriptionTag && seo.description) {
    descriptionTag.setAttribute('content', seo.description);
  }
  const keywordsTag = document.querySelector('meta[name="keywords"]');
  if (keywordsTag && seo.keywords) {
    keywordsTag.setAttribute('content', seo.keywords);
  }
};

export const getContentByType = (page: IPage | null, type: string) => {
  let result = '';
  if (page && Array.isArray(page.content)) {
    const item = page.content.find((contentItem: IPageContent) => contentItem.type === type);
    if (item) {
      result = item.body;
    }
  }

  return result;
};

export const getImgsByGroup = (page: IPage | null, type: string) => {
  if (!page || !Array.isArray(page.images)) {
    return undefined;
  }

  return page.images.filter(({group}) => group === type);
};
