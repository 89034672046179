






















import { Component, Prop, Vue } from 'vue-property-decorator';

export interface IMenuItem extends IMenuSubItem {
  submenu?: IMenuSubItem[];
}

interface IMenuSubItem {
  title: string;
  link: string;
}

@Component
export default class HeaderMenu extends Vue {
  @Prop() private menu!: IMenuItem[];

  private getBgImg = (img: string) => img && `background-image: url(${img})` || '';
}
