









import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Header from '../components/Header.vue';
import LayoutBlock from '../components/LayoutBlock.vue';
import Footer from '../components/Footer.vue';
import { IPage } from '@/types/pages';
import { getContentByType, loadSeo } from '@/utils/pages';
import MainLogo from '@/components/MainLogo.vue';

const PageQueryString = `
  query {
     page(link: ":link") {
      id,
      status,
      seo {
        id,
        title,
        description,
        keywords,
      },
      content(types: "middle_content") {
        type,
        body,
      },
    }
  }
`;

@Component({
  name: 'infrastructure',
  components: {
    MainLogo,
    Footer,
    LayoutBlock,
    Header,
  },
})

export default class Infrastructure extends Vue {
  @Prop() private link!: string;
  private page: IPage | null = null;
  private getContentByType = getContentByType;
  private data() {
    return {
      name: '',
    };
  }
  private async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: gql(PageQueryString.replace(':link', 'istoriya')),
    });
    this.page = data.page;
    if (data.page.seo) {
      loadSeo(data.page.seo);
    }
  }
}
