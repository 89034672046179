











import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class MainLogo extends Vue {
  @Prop() private src!: string;
}
