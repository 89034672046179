













































import { Component, Prop, Vue } from 'vue-property-decorator';
import { groupBy } from 'lodash';
import { INewsItem } from '@/types/news';
import { formatDate, getNewsLink } from '@/utils/news';

@Component
export default class News extends Vue {
  @Prop() private news!: INewsItem[];
  @Prop() private isFirstPage?: boolean;
  @Prop() private noTags?: boolean;
  private formatDate = formatDate;
  private getNewsLink = getNewsLink;

  private getGroups = () => {
    if (!Array.isArray(this.news)) {
      return {};
    }
    const news = this.news.map((value) => ({...value, tagIds: value.tags.map(({id}) => id)}));
    const groupedItems = groupBy(news, 'tagIds');
    const result: any = Object.assign({}, !this.noTags && {0: [...news]}, {...groupedItems});
    if (this.isFirstPage) {
      Object.keys(result).forEach((key) => {
        result[key] = result[key].slice(0, 3);
      });
    }

    return result;
  }

  private getTagName = (id: string) => {
    if (id === '0') {
      return 'Все';
    }
    for (const item of this.news) {
      if (Array.isArray(item.tags)) {
        for (const tag of item.tags) {
          if (String(tag.id) === id) {
            return tag.name;
          }
        }
      }
    }

    return '';
  }
}
