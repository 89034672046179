












































































// @ is an alias to /src
import { Component, Prop, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import { IBasketballPlayer, IBasketballStatistics, IMatch, IShortLong, ISportEvent, ITeamStats } from '@/types/teams';
import TeamMenu from '@/components/TeamMenu.vue';
import { INewsItem } from '@/types/news';
import { loadSeo } from '@/utils/pages';
import { IIndexable, ISeo } from '@/types/pages';
import ResultsTable from '@/components/ResultsTable.vue';
import Leaders from '@/components/Leaders.vue';
import MediaAlbums from '@/components/MediaAlbums.vue';
import TournamentTable from '@/components/TournamentTable.vue';
import Banners from '@/components/Banners.vue';
import { getSeason } from '@/utils/team';

const AllQuery = `
  query {
    news(category: "Баскетбол") {
      title,
      date,
      short,
      show,
      slug,
      images {
        id,
        image,
        title,
      },
      tags {
        id,
        name,
      },
    },
    sportEvents(types: "BASKETBALL_M")  {
      id,
      img,
      link,
    },
    results: matches(types: "BASKETBALL_M", hasResults: true, limit: 5) {
      id,
      place,
      date,
      sportType,
      short,
      left {
       	id,
        fullName,
    	},
      right {
       	id,
        fullName,
      },
      leftScore,
      rightScore,
    },
    stats: basketballTournamentStatistics(season: ":season", sportType: "basketball_m", limit: 5) {
      id,
      season,
      games,
      points,
      teamName,
    },
    players: basketballMPlayers {
      id,
      number,
      position,
      person {
        id,
        firstName,
        lastName,
        avatar,
      },
      statistics {
        id,
        season,
        totalGames,
        allPoints,
        points2,
        points3,
        pointsPenalty,
        gear,
        selections,
        interception,
        loses,
        blockShot,
        fouls,
      },
    },
    matches(types: "BASKETBALL_M", isAfisha: true, limit: 5) {
      id,
      place,
      startTime,
      place,
      date,
      link,
      sportType,
      short,
      left {
       	id,
        avatar,
        fullName,
    	},
      right {
       	id,
        avatar,
        fullName,
      },
    },
  }
`;

@Component({
  name: 'basketball',
  components: {
    Banners,
    TeamMenu,
    News,
    Footer,
    Afisha,
    LayoutBlock,
    Leaders,
    MediaAlbums,
    Header,
    ResultsTable,
    TournamentTable,
  },
})
export default class Basketball extends Vue {
  @Prop() private link!: string;
  private matches: IMatch[] = [];
  private results: IMatch[] = [];
  private news: INewsItem[] = [];
  private sportEvents: ISportEvent[] = [];
  private stats: ITeamStats[] = [];
  private players: IBasketballPlayer[] | null = null;
  private scoreMap: {[key: string]: IShortLong} = {
    games: {short: 'и', long: 'количество игр'},
    points: {short: 'о', long: 'количество очков'},
  };
  private keyMap: IIndexable = {
    BOMB: 'allPoints',
    ASSIST: 'gear',
    RENEGADES: 'fouls',
  };
  private positionMap = {
    DEFENDER: 'Атакующий защитник',
    ATTACKING_DEFENDER: 'Разыгрывающий защитник',
    FORWARD: 'Легкий форвард',
    HEAVY_FORWARD: 'Тяжелый форвард',
    MID: 'Полузащитник',
  };
  private statMap = {
    BOMB: 'Бомбардиры',
    ASSIST: 'Ассистенты',
    RENEGADES: 'Нарушители',
  };
  private scoreLeadersMap: {[key: string]: IShortLong} = {
    allPoints: {short: 'о', long: 'количество очков'},
    gear: {short: 'п', long: 'передачи'},
    fouls: {short: 'ф', long: 'фолы'},
  };
  private scorePositionMap = {
    DEFENDER: this.scoreLeadersMap,
    ATTACKING_DEFENDER: this.scoreLeadersMap,
    FORWARD: this.scoreLeadersMap,
    HEAVY_FORWARD: this.scoreLeadersMap,
    MID: this.scoreLeadersMap,
  };
  private data() {
    return {
      name: '',
    };
  }
  private getScore = (stat: ITeamStats, key: string) => {
    return stat && stat[key as keyof ITeamStats] || 0;
  }
  private getScores = (stats: IBasketballStatistics | void, type: string) => {
    if (!stats) {
      return '';
    }
    const { allPoints } = stats;
    return `${allPoints}`;
  }
  // private updateCurrentStat = (stat?: ICommonStat): IBasketballStatistics => {
  //   // const result = stat as unknown as IBasketballStatistics;
  //   // if (result) {
  //   //   result.allPoints = result.passes + result.totalGoals;
  //   // }
  //
  //   return stat;
  // }
  private async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: gql(AllQuery.replace(':season', getSeason())),
    });
    if (data) {
      this.matches = data.matches;
      this.players = data.players;
      this.sportEvents = data.sportEvents;
      this.results = data.results.filter((result: IMatch) => (
        typeof result.leftScore !== 'undefined' && typeof result.rightScore !== 'undefined'
      ));
      this.news = data.news;

      if (Array.isArray(data.stats)) {
        this.stats = data.stats;
        this.stats.sort((a, b) => b.points - a.points);
        this.stats = this.stats.slice(0, 5);
      }
    }
    loadSeo({title: `СК "Держава" ТГУ — Баскетбол (Мужчины)`, keywords: '', description: ''} as ISeo);
  }
}
