













































import { Component, Vue } from 'vue-property-decorator';
import FooterMenu from './FooterMenu.vue';
const menu = [
  {title: 'О клубе', link: '/club/'},
  {title: 'Команды', link: '/hockey/'},
  {title: 'Спартакиада', link: '/spartakiada/'},
  {title: 'Секции', link: '/sections/'},
  {title: 'Пьедестал', link: '/pedestal/'},
  {title: 'Наша гордость', link: '#'},
];
@Component({
  components: {
    FooterMenu,
  },
  data() {
    return {menu};
  },
})
export default class Footer extends Vue {}
