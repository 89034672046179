











































































// @ is an alias to /src
import { Component, Prop, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import { IHockeyPlayer, IHockeyStatistics, IPerson } from '@/types/teams';
import { loadSeo } from '@/utils/pages';
import { IIndexable, IKeyValue, ISeo } from '@/types/pages';
import { pluralWithNumber } from '@/utils/text';
import TeamMenu from '@/components/TeamMenu.vue';

const HockeyPlayerQueryString = `
  query {
    player: hockeyPlayer(id: :id) {
      id,
      grip,
      number,
      position,
      unitFull,
      history {
        id,
        dateStart,
        dateEnd,
        student,
        team {
          avatar,
          name,
          fullName,
          city,
        }
      },
      person {
        id,
        firstName,
        lastName,
        avatar,
        birthday,
        weight,
        height,
      },
      statistics {
        id,
        season,
        allWins,
        allPoints,
        missedGoals,
        missedAvg,
        penalty,
        totalGoals,
        totalPass,
        totalGames,
      },
    },
  }
`;

@Component({
  name: 'hockey',
  components: {
    News,
    Footer,
    Afisha,
    LayoutBlock,
    Header,
    TeamMenu,
  },
})
export default class HockeyPlayer extends Vue {
  @Prop() private link!: string;
  private player: IHockeyPlayer | null = null;
  private HOCKEY_TYPE: IIndexable = {
    GOALKEEPER: 'Вратарь',
    DEFENDER: 'Защитник',
    FORWARD: 'Нападающий',
  };
  private GRIP_TYPE: IIndexable = {
    LEFT: 'Левый',
    RIGHT: 'Правый',
  };
  private HOCKEY_STATISTICS_MAP: IIndexable = {
    allPoints: 'всего очков',
    totalGoals: 'всего забито',
    totalPass: 'всего передач',
    penalty: 'штрафные минуты',
    totalGames: 'всего игр',
    missedGoals: 'пропущено',
    missedAvg: 'пропущено в среднем',
  };
  private MAP_POSITION_STATS: {[key: string]: string[]} = {
    GOALKEEPER: ['totalGames', 'missedGoals', 'missedAvg', 'penalty'],
    DEFENDER: ['totalGames', 'totalGoals', 'totalPass', 'penalty'],
    FORWARD: ['totalGames', 'totalGoals', 'totalPass', 'penalty'],
  };
  private data() {
    return {
      name: '',
    };
  }
  private async mounted() {
    window.scroll(0, 0);
    const { id } = this.$route.params;
    const { data } = await this.$apollo.query({
      query: gql(HockeyPlayerQueryString.replace(':id', id)),
    });
    if (data) {
      this.player = data.player;
    }
    if (this.player) {
      loadSeo({
        title: `СК "Держава" ТГУ — Состав Хоккей ${this.getFullName(this.player.person)}`,
        keywords: '',
        description: '',
      } as ISeo);
    }
  }

  private getFullName({firstName, lastName}: IPerson) {
    return [firstName, lastName].join(' ');
  }
  private getAge = (birthDate: string) => {
    const diff = (Number(new Date()) - new Date(birthDate).getTime()) / 3.15576e+10 ;

    return Math.floor(diff);
  }

  private getFullAge = (birthDate: string) => {
    const age = this.getAge(birthDate);

    return pluralWithNumber(age, ['год', 'года', 'лет']);
  }
  private formatBirthday = (person: IPerson) => new Date(person.birthday).toLocaleDateString('ru');
  private formatWH = (person: IPerson) => [
    person.height && `${person.height}см`,
    person.weight && `${person.weight}кг`,
  ].filter(Boolean).join('/')

  private getBgImg(img: string) {
    return `background-image: url(${img})`;
  }
  private getPoints(player: IHockeyPlayer): IKeyValue[] {
    const { position, statistics } = player;
    const firstStat = statistics[0];
    if (!firstStat || !this.MAP_POSITION_STATS[position]) {
      return [];
    }

    return this.MAP_POSITION_STATS[position].map((key: string) => ({
      key,
      value: String(firstStat[key as keyof IHockeyStatistics]),
    }));
  }
}
