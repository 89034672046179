





































import gql from 'graphql-tag';
import { chunk, find } from 'lodash';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Header from '../components/Header.vue';
import LayoutBlock from '../components/LayoutBlock.vue';
import Footer from '../components/Footer.vue';
import { IIndexable, IPage } from '@/types/pages';
import { getContentByType, loadSeo } from '@/utils/pages';
import MainLogo from '@/components/MainLogo.vue';

const PageQueryString = `
  query {
     page(link: ":link") {
      id,
      status,
      seo {
        id,
        title,
        description,
        keywords,
      },
    },
    sections(link: ":link") {
      id,
      name,
      place,
      trainer,
      dates {
        weekday,
        startTime,
        endTime,
      }
    }
  }
`;

interface ISectionDate {
  weekday: string;
  startTime: string;
  endTime: string;
}

interface ISection {
  id: string;
  name: string;
  place: string;
  trainer: string;
  dates: ISectionDate[];
}

@Component({
  name: 'sections',
  components: {
    MainLogo,
    Footer,
    LayoutBlock,
    Header,
  },
})
export default class Sections extends Vue {
  @Prop() private link!: string;
  private page: IPage | null = null;
  private sections: ISection[] = [];
  private getContentByType = getContentByType;
  private WEEKDAY_MAP: IIndexable = {
    MON: 'ПН',
    TUE: 'ВТ',
    WED: 'СР',
    THU: 'ЧТ',
    FRI: 'ПТ',
  };
  private data() {
    return {
      name: '',
    };
  }
  private get sectionPairs() {
    return chunk(this.sections, 2);
  }
  private async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: gql(PageQueryString.replace(/:link/ig, 'sekcii')),
    });
    this.page = data.page;
    this.sections = data.sections;
    if (data.page.seo) {
      loadSeo(data.page.seo);
    }
  }
  private getDate = (day: string, dates: ISectionDate[]): ISectionDate | void => find(dates, {weekday: day});
  private getDateClassName(day: string, dates: ISectionDate[]) {
    return [
      'b-sections__date',
      this.getDate(day, dates) && 'b-sections__date--active',
    ].filter(Boolean).join(' ');
  }
  private formatTime = (time: string) => time.slice(0, -3);
  private getFormattedTime = (date: ISectionDate) => [date.startTime, date.endTime]
    .map(this.formatTime).join('&nbsp- ')
}
