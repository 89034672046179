






















import { Component, Prop, Vue } from 'vue-property-decorator';
import { linkTypeMap, SPORT_MAP } from '@/constants/team';
import { IMatch } from '@/types/teams';

@Component
export default class ResultsTable extends Vue {
  @Prop() private results!: IMatch[];
  @Prop() private showFull?: boolean;
  private SPORT_MAP = SPORT_MAP;

  private get type() {
    const { path } = this.$route;

    for (const key of Object.keys(linkTypeMap)) {
      if (path.includes(key)) {
        return linkTypeMap[key];
      }
    }

    return '';
  }
}
