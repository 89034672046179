
























import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';
import Header from '../components/Header.vue';
import LayoutBlock from '../components/LayoutBlock.vue';
import Footer from '../components/Footer.vue';
import { ISeo } from '@/types/pages';
import { getContentByType, loadSeo } from '@/utils/pages';
import MainLogo from '@/components/MainLogo.vue';

const PageQueryString = `
  query {
    person: pridePerson(id: ":id") {
      id,
      title,
      info,
      img,
      position,
    }
  }
`;

interface IPedestalPerson {
  id: string;
  title: string;
  info: string;
  img: string;
  position: number;
}

@Component({
  name: 'pridePerson',
  components: {
    MainLogo,
    Footer,
    LayoutBlock,
    Header,
  },
})
export default class PridePerson extends Vue {
  private person: IPedestalPerson | null = null;
  private getContentByType = getContentByType;
  private data() {
    return {
      name: '',
    };
  }
  private async mounted() {
    window.scroll(0, 0);
    const { id } = this.$route.params;
    const { data } = await this.$apollo.query({
      query: gql(PageQueryString.replace(':id', id)),
    });

    if (data.person) {
      this.person = data.person;
      loadSeo({
        title: `СК "Держава" ТГУ — Наша гордость ${data.person.title}`,
        keywords: '',
        description: '',
      } as ISeo);
    }
  }
}
