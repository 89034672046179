














































// @ is an alias to /src
import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import { groupBy } from 'lodash';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import {
  IFootballPlayer,
  IFootballStatistics,
  IMatch,
  IPlayer,
  IShortLong,
  ITeamStats,
} from '@/types/teams';
import { loadSeo } from '@/utils/pages';
import { IIndexable, ISeo } from '@/types/pages';
import TeamMenu from '@/components/TeamMenu.vue';
import Team, { IGroupedPlayers } from '@/components/Team.vue';
import TournamentTable from '@/components/TournamentTable.vue';
import { getSeason, getSeasonString } from '@/utils/team';

const AllQuery = `
  query {
    players: basketballFPlayers {
      id,
      number,
      position,
      person {
        id,
        firstName,
        lastName,
        avatar,
      },
      statistics {
        id,
        season,
        totalGames,
        allPoints,
        points2,
        points3,
        pointsPenalty,
        gear,
        selections,
        interception,
        loses,
        blockShot,
        fouls,
      },
    },
  }
`;

@Component({
  name: 'basketballFPersonalStat',
  components: {
    Team,
    TeamMenu,
    News,
    Footer,
    Afisha,
    LayoutBlock,
    TournamentTable,
    Header,
  },
})
export default class BasketballFPersonalStat extends Vue {
  private stats: ITeamStats[] = [];
  private get seasonString() {
    return getSeasonString();
  }
  private players: IGroupedPlayers = {};
  private matches: IMatch[] = [];
  private titleMap = {
    DEFENDER: 'Атакующий защитник',
    ATTACKING_DEFENDER: 'Разыгрывающий защитник',
    POINT_GUARD: 'Разыгрывающий',
    MID: 'Центровой',
    FORWARD: 'Легкий форвард',
    HEAVY_FORWARD: 'Тяжелый форвард',
  };
  private statMap = {
    BOMB: 'Бомбардиры',
    ASSIST: 'Ассистенты',
    RENEGADES: 'Нарушители',
  };
  private statPositionMap = {
    '': this.statMap,
    DEFENDER: this.statMap,
    ATTACKING_DEFENDER: this.statMap,
    POINT_GUARD: this.statMap,
    MID: this.statMap,
    FORWARD: this.statMap,
    HEAVY_FORWARD: this.statMap,
  };
  private scoreMap: {[key: string]: IShortLong} = {
    totalGames: {short: 'м', long: 'матчи'},
    allPoints: {short: 'о', long: 'количество очков'},
    points2: {short: '2х', long: '% 2х очковых'},
    points3: {short: '3х', long: '% 3х очковых'},
    pointsPenalty: {short: 'ш', long: '% штрафных'},
    gear: {short: 'а', long: 'передачи'},
    selections: {short: 'п', long: 'подборы'},
    interception: {short: 'х', long: 'перехваты'},
    loses: {short: 'л', long: 'потери'},
    blockShot: {short: 'бш', long: 'блокшоты'},
    fouls: {short: 'ф', long: 'фолы'},
  };
  private scorePositionMap = {
    '': this.scoreMap,
    DEFENDER: this.scoreMap,
    ATTACKING_DEFENDER: this.scoreMap,
    POINT_GUARD: this.scoreMap,
    MID: this.scoreMap,
    FORWARD: this.scoreMap,
    HEAVY_FORWARD: this.scoreMap,
  };
  private async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: gql(AllQuery),
    });
    if (data) {
      this.players = {'': data.players} as IGroupedPlayers;
    }
    const title = 'СК "Держава" ТГУ — Баскетбол (Девушки) | Личная статистика';
    loadSeo({title, keywords: '', description: ''} as ISeo);
  }
  private getScore = (stat: ITeamStats, key: string) => {
    // if (key === 'goalsDiff') {
    //
    // }
    return stat && stat[key as keyof ITeamStats] || 0;
  }

  private getPlaceClassName(place: number) {
    return ['b-tournament__place', place < 1 && 'b-tournament__place--first'].filter(Boolean);
  }

  private getFullName({person: {firstName, lastName}}: IFootballPlayer) {
    return [firstName, lastName].join(' ');
  }

  private getCurrentStat = (player: IFootballPlayer, season: string) => {
    const result = player.statistics.find((stat) => stat.season === season);

    return result;
  }

  private getStat = (key: string, players: IFootballPlayer[]) => {
    const season = `A_${getSeason()}`;
    const result = [...players.map((player) => ({...player, currentStat: this.getCurrentStat(player, season)}))];
    const keyMap: IIndexable = {
      BOMB: 'allPoints',
      ASSIST: 'gear',
      RENEGADES: 'fouls',
    };
    const currentPoints = keyMap[key] as keyof IFootballStatistics;
    result.sort((a, b) => {
      if (!a.currentStat || !b.currentStat) {
        return -1;
      }
      return Number(b.currentStat[currentPoints]) - Number(a.currentStat[currentPoints]);
    });

    return result;
  }

  private getPlayerLink(player: IPlayer) {
    return `/basketball-f/sostav/player/${player.id}/`;
  }
}
