


















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IMenuItem } from './HeaderMenu.vue';

@Component
export default class FooterMenu extends Vue {
  @Prop() private menu!: IMenuItem[];

  private getYear = () => new Date().getFullYear();
}
