


































































// @ is an alias to /src
import { Component, Prop, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import { IStaff, IPerson } from '@/types/teams';
import { loadSeo } from '@/utils/pages';
import { IIndexable, ISeo } from '@/types/pages';
import { pluralWithNumber } from '@/utils/text';
import TeamMenu from '@/components/TeamMenu.vue';

const StaffQueryString = `
  query {
    staff: staffItem(id: :id) {
      id,
      startTime,
      sportType,
      text,
      type,
      position,
      person {
        id,
        firstName,
        lastName,
        middleName,
        avatar,
        birthday,
        weight,
        height,
      },
      history {
        id,
        dateStart,
        dateEnd,
        student,
        team {
          avatar,
          name,
          fullName,
          city,
        },
      },
    },
  },
`;

const linkTypeMap: IIndexable = {
  '/hockey/': 'hockey',
  '/football/': 'football',
  '/basketball-m/': 'basketball-m',
  '/basketball-f/': 'basketball-f',
  '/volleyball-m/': 'volleyball-m',
  '/volleyball-f/': 'volleyball-f',
};

const typeTitleMap: IIndexable = {
  'hockey': 'Хоккей',
  'football': 'Футбол',
  'basketball-m': 'Баскетбол Мужчины',
  'basketball-f': 'Баскетбол Девушки',
  'volleyball-m': 'Волейбол Мужчины',
  'volleyball-f': 'Волейбол Девушки',
};

@Component({
  name: 'hockey',
  components: {
    News,
    Footer,
    Afisha,
    LayoutBlock,
    Header,
    TeamMenu,
  },
})
export default class Hockeystaff extends Vue {
  @Prop() private link!: string;
  private staff: IStaff | null = null;
  private HOCKEY_TYPE = {
    GOALKEEPER: 'Вратарь',
    DEFENDER: 'Защитник',
    FORWARD: 'Нападающий',
  };
  private GRIP_TYPE = {
    LEFT: 'Левый',
    RIGHT: 'Правый',
  };
  private data() {
    return {
      name: '',
    };
  }
  private get type() {
    const { path } = this.$route;

    for (const key of Object.keys(linkTypeMap)) {
      if (path.includes(key)) {
        return linkTypeMap[key];
      }
    }

    return '';
  }
  private async mounted() {
    window.scroll(0, 0);
    const { id } = this.$route.params;
    const { data } = await this.$apollo.query({
      query: gql(StaffQueryString.replace(':id', id)),
    });
    if (data) {
      this.staff = data.staff;
    }
    if (this.staff) {
      loadSeo({
        title: `СК "Держава" ТГУ — Состав ${typeTitleMap[this.type] || ''} ${this.getFullName(this.staff.person)}`,
        keywords: '',
        description: '',
      } as ISeo);
    }
  }

  private getFullName({firstName, lastName, middleName}: IPerson) {
    return [lastName, firstName, middleName].join(' ');
  }
  private getAge = (birthDate: string) => {
    const diff = (Number(new Date()) - new Date(birthDate).getTime()) / 3.15576e+10 ;

    return Math.floor(diff);
  }

  private getFullAge = (birthDate: string) => {
    const age = this.getAge(birthDate);

    return pluralWithNumber(age, ['год', 'года', 'лет']);
  }
  private formatBirthday = (person: IPerson) => new Date(person.birthday).toLocaleDateString('ru');
  private formatWH = (person: IPerson) => [
    person.height && `${person.height}см`,
    person.weight && `${person.weight}кг`,
  ].filter(Boolean).join('/')

  private getBgImg(img: string) {
    return `background-image: url(${img})`;
  }
}
