



































import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';
import Header from '../components/Header.vue';
import LayoutBlock from '../components/LayoutBlock.vue';
import Footer from '../components/Footer.vue';
import { IPage } from '@/types/pages';
import { getContentByType, loadSeo } from '@/utils/pages';
import MainLogo from '@/components/MainLogo.vue';

const PageQueryString = `
  query {
     page: pride {
      id,
      title,
      topContent,
      persons {
        id,
        title,
        info,
        img,
        position,
      },
      seo {
        id,
        title,
        description,
        keywords,
      },
    }
  }
`;

interface IPedestalPerson {
  id: string;
  title: string;
  info: string;
  img: string;
  position: number;
}

interface IPridePage extends IPage {
  title: string;
  topContent: string;
  persons: IPedestalPerson[];
}

/* tslint:disable:next-lint:max-line-length */
const defaultTopContent = 'В данной номинации за право быть лучшим спортсменом университета по спортивным достижениям, показанным в 4-ом квартале 2017 года (октябрь-декабрь) соревнуются 3 студента. Это представители не только разных видов спорта, но и представители разных институтов и факультетов нашего университета.';

@Component({
  name: 'pride',
  components: {
    MainLogo,
    Footer,
    LayoutBlock,
    Header,
  },
})
export default class Pride extends Vue {
  private page: IPridePage | null = null;
  private getContentByType = getContentByType;
  private data() {
    return {
      name: '',
    };
  }

  private getShortText(info: string) {
    return info.split('\n')[0];
  }

  private getLink(person: IPedestalPerson) {
    return `/nasha-gordost/${person.id}`;
  }

  private async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: gql(PageQueryString),
    });

    if (data.page) {
      this.page = data.page;
      if (this.page && !this.page.topContent) {
        this.page.topContent = defaultTopContent;
      }
      if (data.page.seo) {
        loadSeo(data.page.seo);
      }
    }
  }
}
