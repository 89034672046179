



















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IShortLong, ITeamStats } from '@/types/teams';

@Component
export default class TournamentTable extends Vue {
  @Prop() private scoreMap!: {[key: string]: IShortLong};
  @Prop() private stats: ITeamStats[] = [];
  @Prop() private getScore!: (stat: ITeamStats, key: string) => void;
  @Prop() private maxPlace?: number;

  private getPlaceClassName(place: number) {
    return [
      'b-tournament__place',
      (!this.maxPlace || place < this.maxPlace) && 'b-tournament__place--first',
    ].filter(Boolean);
  }
}
