



























































// @ is an alias to /src
import { Component, Prop, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import { IBasketballPlayer, IBasketballStatistics, IPerson } from '@/types/teams';
import { loadSeo } from '@/utils/pages';
import { IIndexable, IKeyValue, ISeo } from '@/types/pages';
import { pluralWithNumber } from '@/utils/text';
import TeamMenu from '@/components/TeamMenu.vue';

const volleyballPlayerQueryString = `
  query {
    player: volleyball:typePlayer(id: :id) {
      id,
      number,
      position,
      unitFull,
      history {
        id,
        dateStart,
        dateEnd,
        student,
        team {
          avatar,
          name,
          fullName,
          city,
        }
      },
      person {
        id,
        firstName,
        lastName,
        avatar,
        birthday,
        weight,
        height,
      },
    },
  }
`;

@Component({
  name: 'volleyballPlayer',
  components: {
    News,
    Footer,
    Afisha,
    LayoutBlock,
    Header,
    TeamMenu,
  },
})
export default class VolleyballPlayer extends Vue {
  @Prop() private link!: string;
  private player: IBasketballPlayer | null = null;
  private POSITION_TYPE: IIndexable = {
    FORWARD: 'Нападающий',
    LIBERO: 'Либеро',
    PASSING: 'Пасующий',
  };
  private data() {
    return {
      name: '',
    };
  }
  private async mounted() {
    window.scroll(0, 0);
    const { id } = this.$route.params;
    const type = this.$route.path.includes('volleyball-m') ? 'M' : 'F';
    const { data } = await this.$apollo.query({
      query: gql(volleyballPlayerQueryString.replace(':id', id).replace(':type', type)),
    });
    if (data) {
      this.player = data.player;
    }
    if (this.player) {
      loadSeo({
        title: `СК "Держава" ТГУ — Состав Волейбол ${this.getFullName(this.player.person)}`,
        keywords: '',
        description: '',
      } as ISeo);
    }
  }

  private getFullName({firstName, lastName}: IPerson) {
    return [firstName, lastName].join(' ');
  }
  private getAge = (birthDate: string) => {
    const diff = (Number(new Date()) - new Date(birthDate).getTime()) / 3.15576e+10 ;

    return Math.floor(diff);
  }

  private getFullAge = (birthDate: string) => {
    const age = this.getAge(birthDate);

    return pluralWithNumber(age, ['год', 'года', 'лет']);
  }
  private formatBirthday = (person: IPerson) => new Date(person.birthday).toLocaleDateString('ru');
  private formatWH = (person: IPerson) => [
    person.height && `${person.height}см`,
    person.weight && `${person.weight}кг`,
  ].filter(Boolean).join('/')

  private getBgImg(img: string) {
    return `background-image: url(${img})`;
  }
  private get headerLink() {
    const type = this.$route.path.includes('volleyball-m') ? 'm' : 'f';

    return `volleyball-${type}`;
  }
}
