







import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class LayoutBlock extends Vue {
  @Prop() private title!: string;
  @Prop() private titleClass!: string;
}
