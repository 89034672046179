<template>
  <div class="home">
    <Header />
    <div class="container__wrapper">
      <div class="container">
        <div class="row">
          <div class="col-12">
            <LayoutBlock title="Новости">
              <News :news="news" />
            </LayoutBlock>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>


<script>
  import gql from 'graphql-tag';
  import Header from '../components/Header';
  import LayoutBlock from '../components/LayoutBlock';
  import Footer from '../components/Footer';
  import News from '../components/News';
  import { loadSeo } from '../utils/pages';
  import { ISeo } from '../types/pages';

  const NewsQuery = gql`
  query {
    news {
      title,
      date,
      short,
      show,
      slug,
      images {
        id,
        image,
        title,
      },
      tags {
        id,
        name,
      },
    }
  }
`;

  export default {
    name: 'home',
    components: {
      News,
      Footer,
      LayoutBlock,
      Header,
    },
    data() {
      return {
        name: '',
      };
    },
    apollo: {
      news: NewsQuery,
    },
    mounted() {
      window.scroll(0, 0);
      const seo = {title: `СК "Держава" ТГУ — Новости`, keywords: '', description: ''};
      loadSeo(seo);
    },
  };
</script>

<style lang="scss">
</style>
