































import { Component, Prop, Vue } from 'vue-property-decorator';
import MainLogo from './MainLogo.vue';
import HeaderMenu from './HeaderMenu.vue';
import gql from 'graphql-tag';
const menu = [
    {
      title: 'О клубе',
      submenu: [
        {title: 'Клуб', link: '/club/'},
        {title: 'История', link: '/istoriya/'},
        {title: 'Спортивная инфраструктура', link: '/sportivnaya-infrastruktura/'},
      ],
    },
    {
      title: 'Команды',
      submenu: [
        {title: 'Баскетбол (мужчины)', link: '/basketball-m/'},
        {title: 'Баскетбол (женщины)', link: '/basketball-f/'},
        {title: 'Волейбол (мужчины)', link: '/volleyball-m/'},
        {title: 'Волейбол (женшины)', link: '/volleyball-f/'},
        {title: 'Футбол', link: '/football/'},
        {title: 'Хоккей', link: '/hockey/'},
      ],
    },
    {
      title: 'Спартакиада',
      link: '/spartakiada/',
      submenu: [
        {title: 'Новости', link: '/spartakiada/news/'},
        {title: 'Календарь', link: '/spartakiada/calendar/'},
        {title: 'Таблица', link: '/spartakiada/'},
        {title: 'Положение о проведении', link: '/media/upload/attachment/source/polozhenie_o_spartakiade.pdf'},
      ],
    },
    {title: 'Секции', link: '/sections/'},
    {title: 'Медиа', link: '/photo-video/'},
    {title: 'Пьедестал', link: '/pedestal/'},
    {title: 'Наша гордость', link: '/nasha-gordost/'},
  ];
const headerQuery = `
  query {
    customHeader(link: ":link") {
    img,
    formText,
    formUrl,
    logo,
    title,
    info,
    include,
  },
}`;
@Component({
  components: {
    HeaderMenu,
    MainLogo,
  },
  data() {
    return {menu};
  },
})
export default class Header extends Vue {
  @Prop() private className!: string;
  @Prop() private showForm?: boolean;

  private img: string = '';
  private isFormVisible: boolean = false;
  private title: string = 'Бейся за вуз! \nСражайся за «Державу»!';
  private info: string = 'Спортивный клуб \nТГУ им. Г.Р. Державина';
  private logo: string = '/static/logo.svg';
  private formText: string = '<strong>СТАНЬ ЧАСТЬЮ КЛУБА «ДЕРЖАВА».</strong> ЗАПОЛНИ ЗАЯВКУ И РАСКАЖИ О СЕБЕ';
  private formUrl: string = '';

  private getContainerClassName(className?: string) {
    return [className, 'main-header'].filter(Boolean).join(' ');
  }

  private async mounted() {
    window.scroll(0, 0);
    const { path } = this.$route;
    const { data } = await this.$apollo.query({
      query: gql(headerQuery.replace(':link', path)),
    });
    if (data.customHeader) {
      this.img = data.customHeader.img;
      this.logo = data.customHeader.logo || this.logo;
      this.title = data.customHeader.title || this.title;
      this.info = data.customHeader.info || this.info;
      this.formText = data.customHeader.formText || this.formText;
      this.formUrl = data.customHeader.formUrl || this.formUrl;
    }
  }
  private getBgImg(img: string) {
    if (!img) {
      return '';
    }

    return `background-image: url(${img})`;
  }
}
