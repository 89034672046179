import Vue, { VNode } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import apolloProvider from './vue-apollo';
import BootstrapVue from 'bootstrap-vue';
// @ts-ignore
import VueMasonry from 'vue-masonry-css';
import Lightbox from 'vue-easy-lightbox';
import VueYouTubeEmbed from 'vue-youtube-embed';

Vue.use(BootstrapVue);
Vue.use(VueMasonry);
Vue.use(VueYouTubeEmbed);
Vue.use(Lightbox);

Vue.config.productionTip = false;

new Vue({
  apolloProvider,
  router,
  store,
  render: (h: (app: typeof App) => VNode) => h(App),
}).$mount('#app');
