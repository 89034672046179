




































































  import gql from 'graphql-tag';
  import { Component, Vue } from 'vue-property-decorator';
  import { get, groupBy } from 'lodash';
  import { slugify } from 'transliteration';

  import Header from '../components/Header.vue';
  import Footer from '../components/Footer.vue';
  import { loadSeo } from '../utils/pages';
  import { formatDate } from '@/utils/news';
  import { ISeo } from '@/types/pages';
  import { IFestival, IFestivalResult } from '../types/teams';
  import { getSeason, getSeasonRange } from '@/utils/team';


  const FestivalsQuery = `
  query {
   festivals(start: :start, end: :end) {
    id,
    name,
		avatar,
		text,
    images {
      id,
      image,
      title,
    },
  },
  festivalResults(start: :start, end: :end) {
    id,
    points,
    type,
    sport,
    unitName,
  }
}
`;

  @Component({
    name: 'spartakiadaItem',
    components: {
      Footer,
      Header,
    },
  })

  export default class SpartakiadaItem extends Vue {
    private festivals: IFestival[] | null = null;
    private currentFestival: IFestival | null = null;
    private festivalResults: IFestivalResult[] | null = null;
    private formatDate = formatDate;

    private data() {
      return {
        name: '',
        typeNames: {
          MALE: 'Юноши',
          FEMALE: 'Девушки',
        },
      };
    }

    private async mounted() {
      window.scroll(0, 0);
      const { path } = this.$route.params;
      const seo = {title: `СК "Держава" ТГУ — Спартакиада`, keywords: '', description: ''};
      loadSeo(seo as ISeo);
      const [start, end] = getSeasonRange();
      const {data} = await this.$apollo.query({
        query: gql(FestivalsQuery.replace(/:start/ig, `20${start}`).replace(/:end/ig, `20${end}`)),
      });
      this.festivals = data.festivals;
      this.festivalResults = data.festivalResults;
      this.currentFestival = data.festivals.find(({name}: IFestival) => slugify(name) === path);
    }
    private getBgImg(img: string) {
      return `background-image: url(${img})`;
    }
    private getSortedByType(type: string) {
      let result: IFestivalResult[] = [];
      if (this.festivalResults) {
        result = this.festivalResults.filter((res) => res.type === type);
        // Сортируем по id спорта
        result.sort((a, b) => a.sport - b.sport);
      }

      return result;
    }
    private getSortedResults(type: string): IFestival[] {
      if (!this.festivals || !this.festivalResults) {
        return [];
      }
      const ids = this.festivalResults.filter((res) => res.type === type).map(({sport}) => String(sport));
      const result = this.festivals.filter(({id}) => ids.includes(id));
      result.sort((a, b) => Number(a.id) - Number(b.id));

      return result;
    }
    private getSportAvatars(type: string) {
      return this.getSortedResults(type).map(({avatar}) => avatar);
    }
    private get currentId(): string {
      return this.currentFestival && this.currentFestival.id || '';
    }
    private getLastPoint(value: IFestivalResult[]) {
      const element = this.currentFestival
        && value.find(({sport}) => this.currentId === String(sport))
        || value[value.length - 1];

      return element && element.points || 0;
    }
    private getTitle(items: IFestivalResult[]) {
      return get(items, '0.unitName');
    }
    private getTable(type: string) {
      let result: IFestivalResult[][] = [];
      if (this.festivalResults) {
        const results = this.getSortedByType(type);
        const groupedResults = groupBy(results, 'unitName');
        Object.keys(groupedResults).forEach((key) => {
          if (!groupedResults[key]) {
            return;
          }
          const item = groupedResults[key] || [];
          const sum = item.reduce((acc, value) => acc + value.points, 0);
          groupedResults[key] = [...groupedResults[key], {points: sum} as IFestivalResult];
        });
        result = Object.values(groupedResults);
      }
      result.sort((a, b) => this.getLastPoint(a) - this.getLastPoint(b));

      return result;
    }
    private getPlaceClassName(place: number) {
      return ['b-festivals__place', place < 3 && 'b-festivals__place--first'].filter(Boolean);
    }
    private get currentIndex() {
      if (!this.currentFestival) {
        return -1;
      }
      const { id } = this.currentFestival;
    }
    private showTable(type: string) {
      if (!this.currentFestival) {
        return false;
      }

      return Boolean(this.getSortedResults(type).find(({id}) => id === this.currentId));
    }
    private getCellPointClassName(item: IFestivalResult) {
      return [
        'b-festivals__col b-festivals__col-point',
        this.currentFestival && this.currentFestival.id === String(item.sport) && 'b-festivals__col-point--active',
      ].filter(Boolean).join(' ');
    }

    private get seasonString() {
      const [start, end] = getSeasonRange();

      return `20${start}-20${end} учебный год`
    }
  }
