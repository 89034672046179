









import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import { IHockeyPlayer, IStaff } from '@/types/teams';
import { loadSeo } from '@/utils/pages';
import { ISeo } from '@/types/pages';
import TeamMenu from '@/components/TeamMenu.vue';
import Team from '@/components/Team.vue';
import { INewsItem } from '@/types/news';


export interface IGroupedPlayers {
  [key: string]: IHockeyPlayer[];
}

export interface IGroupedStaff {
  [key: string]: IStaff[];
}

@Component({
  name: 'hockeyTeam',
  components: {
    Team,
    TeamMenu,
    News,
    Footer,
    Afisha,
    LayoutBlock,
    Header,
  },
})
export default class HockeyTeam extends Vue {
  // private hockeyPlayers: IGroupedPlayers = {};
  // private staff: IGroupedStaff = {};
  private news: INewsItem[] = [];
  private titleMap = {
    GOALKEEPER: 'Вратари',
    DEFENDER: 'Защитники',
    FORWARD: 'Нападающие',
  };
  private data() {
    return {
      name: '',
    };
  }
  private async mounted() {
    window.scroll(0, 0);
    loadSeo({title: 'СК "Держава" ТГУ — Состав', keywords: '', description: ''} as ISeo);
  }
}
