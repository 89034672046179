








































  import gql from 'graphql-tag';
  import { Component, Vue } from 'vue-property-decorator';
  import { slugify } from 'transliteration';

  import Header from '../components/Header.vue';
  import Footer from '../components/Footer.vue';
  import { loadSeo } from '../utils/pages';
  import { formatDate, formatTime } from '@/utils/news';
  import { ISeo } from '@/types/pages';
  import { IFestival, IFestivalEvent } from '@/types/teams';
  import { getSeasonRange } from '@/utils/team';


  const FestivalsQuery = `
  query {
   festivals(start: :start, end: :end) {
    id,
    name,
		avatar,
  },
  festivalEvents {
    id,
    date,
    place,
    sport,
    startTime,
  },
}
`;

  @Component({
    name: 'spartakiada',
    components: {
      Footer,
      Header,
    },
  })

  export default class SpartakiadaCalendar extends Vue {
    private festivals: IFestival[] | null = null;
    private festivalEvents: IFestivalEvent[] | null = null;
    private formatDate = formatDate;
    private formatTime = formatTime;

    private data() {
      return {
        name: '',
      };
    }

    private async mounted() {
      window.scroll(0, 0);
      const seo = {title: `СК "Держава" ТГУ — Спартакиада Календарь`, keywords: '', description: ''};
      loadSeo(seo as ISeo);
      const [start, end] = getSeasonRange();
      const {data} = await this.$apollo.query({
        query: gql(FestivalsQuery.replace(/:start/ig, `20${start}`).replace(/:end/ig, `20${end}`)),
      });
      const { festivals, festivalEvents } = data;
      this.festivals = festivals;
      if (festivalEvents) {
        this.festivalEvents = (festivalEvents as IFestivalEvent[]).map((event) => ({
          ...event,
          festival: festivals.find(({id}: IFestival) => id === String(event.sport)),
        })).filter(({ festival }) => festival);
      }
    }
    private getBgImg(img: string) {
      return `background-image: url(${img})`;
    }
    private getLink(item: IFestival) {
      return `/spartakiada/${slugify(item.name)}/`;
    }

    private getTextDate(date: string) {
      const [first, second] = formatDate(date, {includeYear: false}).split(' ');
      return `<span>${first}</span><span>${second}</span>`;
    }

    private get seasonString() {
      const [start, end] = getSeasonRange();

      return `20${start}-20${end} учебный год`
    }
  }
