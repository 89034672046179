


































import { Component, Prop, Vue } from 'vue-property-decorator';
import { SPORT_MAP } from '@/constants/team';
import { IMatch } from '@/types/teams';
import { ISeo } from '@/types/pages';
import { loadSeo } from '@/utils/pages';

@Component
export default class Result extends Vue {
  @Prop() private result!: IMatch;
  private SPORT_MAP = SPORT_MAP;

  private formatDate = (date: string) =>
    new Date(date).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }).slice(0, -3)


  private async mounted() {
    window.scroll(0, 0);
    loadSeo({title: `СК "Держава" ТГУ — Результаты`, keywords: '', description: ''} as ISeo);
  }
}
