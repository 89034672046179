


























































import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Header from '../components/Header.vue';
import LayoutBlock from '../components/LayoutBlock.vue';
import Footer from '../components/Footer.vue';
import { IIndexable, IPage } from '@/types/pages';
import { getContentByType, getImgsByGroup, loadSeo } from '@/utils/pages';
import MainLogo from '@/components/MainLogo.vue';
import TeamMenu from '@/components/TeamMenu.vue';

const PageQueryString = `
  query {
    page(link: ":link") {
      id,
      status,
      title,
      seo {
        id,
        title,
        description,
        keywords,
      },
      images {
        group,
        id,
        image,
        title,
      },
      content(types: "top_content,bottom_content,bottom_content_title,content") {
        type,
        body,
      },
      achievements {
        id,
        year,
        info,
      },
    }
  }
`;

@Component({
  name: 'teamHistory',
  components: {
    TeamMenu,
    MainLogo,
    Footer,
    LayoutBlock,
    Header,
  },
})

export default class TeamHistory extends Vue {
  @Prop() private link!: string;
  private page: IPage | null = null;
  private getContentByType = getContentByType;
  private getImgsByGroup = getImgsByGroup;
  private linkTypeMap: IIndexable = {
    '/hockey/': 'hockey',
    '/football/': 'football',
    '/basketball-m/': 'basketball-m',
    '/basketball-f/': 'basketball-f',
    '/volleyball-m/': 'volleyball-m',
    '/volleyball-f/': 'volleyball-f',
  };
  private data() {
    return {
      name: '',
    };
  }
  private async mounted() {
    window.scroll(0, 0);
    const { path } = this.$route.params;
    let link = path;
    if (['istoriya-basketball'].includes(path)) {
      link = `${path}${this.type.slice(-2)}`
    }
    const { data } = await this.$apollo.query({
      query: gql(PageQueryString.replace(':link', link)),
    });
    if (data.page) {
      this.page = data.page;
      if (data.page.seo) {
        loadSeo(data.page.seo);
      }
    }
  }

  private getBgImg(img: string) {
    if (!img) {
      return '';
    }

    return `background-image: url(${img})`;
  }

  private get isHockeyPage() {
    const { path } = this.$route;

    return path.includes('/hockey/');
  }

  private get type() {
    const { path } = this.$route;

    for (const key of Object.keys(this.linkTypeMap)) {
      if (path.includes(key)) {
        return this.linkTypeMap[key];
      }
    }

    return '';
  }
}
