





















import { Component, Prop, Vue } from 'vue-property-decorator';
import Header from '../components/Header.vue';
import LayoutBlock from '../components/LayoutBlock.vue';
import Footer from '../components/Footer.vue';
import { IPage } from '@/types/pages';
import { getContentByType, loadSeo } from '@/utils/pages';
import MainLogo from '@/components/MainLogo.vue';
import Banners from '@/components/Banners.vue';
import { form as searchCode, results as resultsCode } from './search.json';
/* tslint:disable-next-line */

@Component({
  name: 'search',
  components: {
    Banners,
    MainLogo,
    Footer,
    LayoutBlock,
    Header,
  },
})

export default class Search extends Vue {
  @Prop() private link!: string;
  private page: IPage | null = null;
  private getContentByType = getContentByType;
  private searchCode = searchCode;
  private resultsCode = resultsCode;
  private data() {
    return {
      name: '',
    };
  }
  private async mounted() {
    window.scroll(0, 0);
    /* tslint:disable-next-line */
    eval("(function(w,d,c){var s=d.createElement('script'),h=d.getElementsByTagName('script')[0],e=d.documentElement;if((' '+e.className+' ').indexOf(' ya-page_js_yes ')===-1){e.className+=' ya-page_js_yes';}s.type='text/javascript';s.async=true;s.charset='utf-8';s.src=(d.location.protocol==='https:'?'https:':'http:')+'//site.yandex.net/v2.0/js/all.js';h.parentNode.insertBefore(s,h);(w[c]||(w[c]=[])).push(function(){Ya.Site.Form.init()})})(window,document,'yandex_site_callbacks');");

    /* tslint:disable-next-line */
    eval("(function(w,d,c){var s=d.createElement('script'),h=d.getElementsByTagName('script')[0];s.type='text/javascript';s.async=true;s.charset='utf-8';s.src=(d.location.protocol==='https:'?'https:':'http:')+'//site.yandex.net/v2.0/js/all.js';h.parentNode.insertBefore(s,h);(w[c]||(w[c]=[])).push(function(){Ya.Site.Results.init();})})(window,document,'yandex_site_callbacks');");
  }
}
