









































































// @ is an alias to /src
import { Component, Prop, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import { IFootballPlayer, IFootballStatistics, IPerson } from '@/types/teams';
import { loadSeo } from '@/utils/pages';
import { IIndexable, IKeyValue, ISeo } from '@/types/pages';
import { pluralWithNumber } from '@/utils/text';
import TeamMenu from '@/components/TeamMenu.vue';

const FootballPlayerQueryString = `
  query {
    player: footballPlayer(id: :id) {
      id,
      number,
      position,
      unitFull,
      history {
        id,
        dateStart,
        dateEnd,
        student,
        team {
          avatar,
          name,
          fullName,
          city,
        }
      },
      person {
        id,
        firstName,
        lastName,
        avatar,
        birthday,
        weight,
        height,
      },
      statistics {
        id,
        season,
        matches,
        totalGoals,
        passes,
        redCards,
        yellowCards,
      },
    },
  }
`;

@Component({
  name: 'footballPlayer',
  components: {
    News,
    Footer,
    Afisha,
    LayoutBlock,
    Header,
    TeamMenu,
  },
})
export default class FootballPlayer extends Vue {
  @Prop() private link!: string;
  private player: IFootballPlayer | null = null;
  private POSITION_TYPE: IIndexable = {
    GOALKEEPER: 'Вратарь',
    DEFENDER: 'Защитник',
    MID: 'Полузащитник',
    FORWARD: 'Нападающий',
  };
  private STATISTICS_MAP: IIndexable = {
    matches: 'матчей сыграно',
    totalGoals: 'мячей забито',
    passes: 'пас',
    goalsPasses: 'гол+пас',
    redCards: 'количество красных карточек',
    yellowCards: 'количество жёлтых карточек',
  };
  private MAP_POSITION_STATS: {[key: string]: string[]} = {
    GOALKEEPER: ['matches', 'totalGoals', 'passes', 'goalsPasses'],
    DEFENDER: ['matches', 'totalGoals', 'passes', 'goalsPasses'],
    FORWARD: ['matches', 'totalGoals', 'passes', 'goalsPasses'],
    MID: ['matches', 'totalGoals', 'passes', 'goalsPasses'],
  };
  private data() {
    return {
      name: '',
    };
  }
  private async mounted() {
    window.scroll(0, 0);
    const { id } = this.$route.params;
    const { data } = await this.$apollo.query({
      query: gql(FootballPlayerQueryString.replace(':id', id)),
    });
    if (data) {
      this.player = data.player;
    }
    if (this.player) {
      loadSeo({
        title: `СК "Держава" ТГУ — Состав Хоккей ${this.getFullName(this.player.person)}`,
        keywords: '',
        description: '',
      } as ISeo);
    }
  }

  private getFullName({firstName, lastName}: IPerson) {
    return [firstName, lastName].join(' ');
  }
  private getAge = (birthDate: string) => {
    const diff = (Number(new Date()) - new Date(birthDate).getTime()) / 3.15576e+10 ;

    return Math.floor(diff);
  }

  private getFullAge = (birthDate: string) => {
    const age = this.getAge(birthDate);

    return pluralWithNumber(age, ['год', 'года', 'лет']);
  }
  private formatBirthday = (person: IPerson) => new Date(person.birthday).toLocaleDateString('ru');
  private formatWH = (person: IPerson) => [
    person.height && `${person.height}см`,
    person.weight && `${person.weight}кг`,
  ].filter(Boolean).join('/')

  private getBgImg(img: string) {
    return `background-image: url(${img})`;
  }
  private getPoint(stat: IFootballStatistics, key: string) {
    if (key === 'goalsPasses') {
      return (stat.totalGoals || 0) + (stat.passes || 0);
    } else {
      return stat[key as keyof IFootballStatistics];
    }
  }
  private getPoints(player: IFootballPlayer): IKeyValue[] {
    const { position, statistics } = player;
    const firstStat = statistics[0];
    if (!firstStat || !this.MAP_POSITION_STATS[position]) {
      return [];
    }

    return this.MAP_POSITION_STATS[position].map((key: string) => ({
      key,
      value: String(this.getPoint(firstStat, key)),
    }));
  }
}
