







































// @ is an alias to /src
import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import { IMatch, IShortLong, ISportEvent, ITeamStats } from '@/types/teams';
import { loadSeo } from '@/utils/pages';
import { ISeo } from '@/types/pages';
import TeamMenu from '@/components/TeamMenu.vue';
import Team from '@/components/Team.vue';
import TournamentTable from '@/components/TournamentTable.vue';
import { getSeason, getSeasonString } from '@/utils/team';

const AllQuery = `
  query {
    stats: basketballTournamentStatistics(season: ":season", sportType: "basketball_f") {
      id,
      deactivated,
      season,
      games,
      wins,
      loses,
      goalsFrom,
      goalsTo,
      points,
      lastFive,
      teamName,
    },
    sportEvents(types: "BASKETBALL_F")  {
      id,
      img,
      link,
    },
    matches(types: "basketball_f", isAfisha: true, limit: 5) {
      id,
      place,
      startTime,
      place,
      date,
      sportType,
      short,
      left {
       	id,
       	avatar,
        fullName,
    	},
      right {
       	id,
       	avatar,
        fullName,
      },
    },
  }
`;

interface IGameResult {
  [key: string]: {
    letter: string;
    className: string;
  };
}

@Component({
  name: 'basketballMStat',
  components: {
    Team,
    TeamMenu,
    News,
    Footer,
    Afisha,
    LayoutBlock,
    TournamentTable,
    Header,
  },
})
export default class BasketballFStat extends Vue {
  private stats: ITeamStats[] = [];
  private get seasonString() {
    return getSeasonString();
  }
  private matches: IMatch[] = [];
  private sportEvents: ISportEvent[] = [];
  private scoreMap: {[key: string]: IShortLong} = {
    games: {short: 'и', long: 'количество игр'},
    wins: {short: 'в', long: 'выигрыши'},
    loses: {short: 'п', long: 'поражения'},
    percents: {short: '%', long: 'процент побед'},
    goalsFrom: {short: 'мз', long: 'мячей забито'},
    goalsTo: {short: 'мп', long: 'мячей пропущено'},
    goalsDiff: {short: '+/-', long: 'разница мячей'},
    points: {short: 'о', long: 'количество очков'},
    lastFive: {short: 'последние 5', long: ''},
  };
  private gameResultMap: IGameResult = {
    0: {letter: 'П', className: 'lose'},
    1: {letter: 'В', className: 'win'},
  };
  private data() {
    return {
      name: '',
    };
  }
  private async mounted() {
    window.scroll(0, 0);
    const season = (window as any).__derzhavaConfig.season;
    const { data } = await this.$apollo.query({
      query: gql(AllQuery.replace(':season', getSeason())),
    });
    if (data) {
      if (Array.isArray(data.stats)) {
        this.stats = data.stats;
        this.stats.sort((a, b) => b.points - a.points);
      }
      this.matches = data.matches;
      this.sportEvents = data.sportEvents;
    }
    const title = 'СК "Держава" ТГУ — Баскетбол Девушки | Турнирная таблица';
    loadSeo({title, keywords: '', description: ''} as ISeo);
  }
  private getScore = (stat: ITeamStats, key: string) => {
    if (key === 'goalsDiff') {
      const { goalsFrom = 0, goalsTo = 0 } = stat;
      return String(goalsFrom - goalsTo);
    } else if (key === 'percents') {
      const { wins = 0, games = 0 } = stat;
      return String(Math.round(wins / games * 100));
    } else if (key === 'lastFive') {
      const innerPoints = stat.lastFive.split('|').slice(0, 5).map((point) =>
        `<span class="${this.gameResultMap[point].className}">${this.gameResultMap[point].letter}</span>`,
      ).join('');

      return `<div class="d-flex justify-content-center">${innerPoints}</div>`;
    } else {
      return stat && stat[key as keyof ITeamStats] || 0;
    }
  }
}
