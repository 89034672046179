




















































import gql from 'graphql-tag';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Footer from '@/components/Footer.vue';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { INewsItem } from '@/types/news';
import { formatDate } from '@/utils/news';
import { loadSeo } from '@/utils/pages';
import { ISeo } from '@/types/pages';

const NewsItemQueryString = `
  query {
    newsItem(link: ":link") {
      id,
      title,
      date,
      text,
      show,
      slug,
      tags {
        id,
        name,
      },
      images {
        id,
        image,
        title,
      }
    }
  }
`;

@Component({
  name: 'newsItem',
  components: {
    Footer,
    LayoutBlock,
    Header,
  },
})

export default class NewsItem extends Vue {
  @Prop() private link!: string;
  private newsItem: INewsItem | null = null;
  private formatDate = formatDate;
  private data() {
    return {
      name: '',
    };
  }
  private async mounted() {
    window.scroll(0, 0);
    const { path } = this.$route.params;
    const { data } = await this.$apollo.query({
      query: gql(NewsItemQueryString.replace(':link', path)),
    });
    this.newsItem = data.newsItem;
    loadSeo({title: `СК "Держава" ТГУ — ${data.newsItem.title}`, keywords: '', description: ''} as ISeo);
  }
  private isNotLoaded = () => this.newsItem === null;
}
