













import { Component, Prop, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import Banner from '@/components/Banner.vue';

const bannerQuery = `
  query {
    customBanner(link: ":link") {
    topImg,
    topLink,
    bottomImg,
    bottomLink,
    include,
  },
}`;

@Component({
  components: {
    Banner,
  },
})
export default class Banners extends Vue {
  @Prop() private className!: string;
  @Prop() private reversed?: boolean;
  @Prop() private hideBig?: boolean;
  @Prop() private hideSmall?: boolean;

  private topImg: string = '/static/banner1.png';
  private topLink: string = '';
  private bottomImg: string = '/static/banner2.png';
  private bottomLink: string = '';


  private async mounted() {
    const { path } = this.$route;
    const { data } = await this.$apollo.query({
      query: gql(bannerQuery.replace(':link', path)),
    });
    if (data.customBanner) {
      this.topImg = data.customBanner.topImg;
      this.topLink = data.customBanner.topLink;
      this.bottomImg = data.customBanner.bottomImg;
      this.bottomLink = data.customBanner.bottomLink;
    }
  }
  private getBgImg(img: string) {
    if (!img) {
      return '';
    }

    return `background-image: url(${img})`;
  }
}
