



















































import { Component, Prop, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import { getIdFromURL } from 'vue-youtube-embed';

import { formatDate, getNewsLink } from '@/utils/news';
import { IIndexable, IMediaAlbum, IMediaVideo } from '@/types/pages';
import LayoutBlock from '@/components/LayoutBlock.vue';
import { SPORT_MAP } from '@/constants/team';

const mediaQuery = `
  query {
    albums(pages: ":pages", types: ":types", limit: :limit) {
      id,
      sportType,
      type,
      date,
      title,
      images(limit: :limit) {
        id,
        thumb,
        image,
        title,
      },
      videos(limit: :limit) {
        title,
        url,
      },
    },
  },
`;
type TMediaImageModel = IMediaAlbum['images'][0] & IMediaAlbum['type'];
type TMediaVideoModel = IMediaAlbum['videos'][0] & IMediaAlbum['type'];

@Component({
  components: {
    LayoutBlock,
  },
})
export default class MediaAlbums extends Vue {
  @Prop() private pages!: string;
  @Prop() private types!: string;
  @Prop() private isFull?: boolean;
  private albums: IMediaAlbum[] = [];
  private formatDate = formatDate;
  private getNewsLink = getNewsLink;
  private SPORT_MAP = SPORT_MAP;
  private ALBUM_TYPE_MAP: IIndexable = {
    0: 'Все',
    1: 'Видео',
    2: 'Фото',
  };

  private get limit() {
    return this.isFull ? 99999999 : 8;
  }
  private getItems = (albums: IMediaAlbum[]) => {
    if (!Array.isArray(albums)) {
      return {};
    }
    const photos: TMediaImageModel[] = albums.reduce((acc: TMediaImageModel[], value: IMediaAlbum) => [
      ...acc,
      ...value.images.slice(0, 1).map((item) => ({
        ...item, type: value.type, title: item.title || value.title, albumId: value.id,
      } as unknown as TMediaImageModel)),
    ], [] as TMediaImageModel[]);
    const videos: TMediaVideoModel[] = albums.reduce((acc: TMediaVideoModel[], value: IMediaAlbum) => [
      ...acc,
      ...value.videos.map((item) => ({
        ...item, type: value.type, title: item.title || value.title,
      } as unknown as TMediaVideoModel)),
    ], [] as TMediaVideoModel[]);
    const limit = this.limit;

    return Object.assign(
      {0: [...photos, ...videos].slice(0, limit)},
      photos.length && {2: photos.slice(0, limit)},
      videos.length && {1: videos.slice(0, limit)},
    );
  }
  private getVideoId = (video: IMediaVideo) => {
    return getIdFromURL(video.url);
  }

  private async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: gql(mediaQuery
        .replace(':pages', this.pages || '')
        .replace(':types', this.types || '')
        .replace(/:limit/ig, String(this.limit * 2)),
      ),
    });
    if (data.albums) {
      this.albums = data.albums;
    }
  }
}
