












































// @ is an alias to /src
import { Component, Prop, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import { IMatch, IShortLong, ISportEvent, ITeamStats } from '@/types/teams';
import TeamMenu from '@/components/TeamMenu.vue';
import { INewsItem } from '@/types/news';
import { loadSeo } from '@/utils/pages';
import { ISeo } from '@/types/pages';
import ResultsTable from '@/components/ResultsTable.vue';
import MediaAlbums from '@/components/MediaAlbums.vue';
import TournamentTable from '@/components/TournamentTable.vue';
import Banners from '@/components/Banners.vue';

const AllQuery = gql`
  query {
    news(category: "Волейбол") {
      title,
      date,
      short,
      show,
      slug,
      images {
        id,
        image,
        title,
      },
      tags {
        id,
        name,
      },
    },
    sportEvents(types: "volleyball_m")  {
      id,
      img,
      link,
    },
    results: matches(types: "volleyball_m", hasResults: true, limit: 5) {
      id,
      place,
      date,
      sportType,
      short,
      left {
       	id,
        fullName,
    	},
      right {
       	id,
        fullName,
      },
      leftScore,
      rightScore,
    },
    matches(types: "volleyball_m", isAfisha: true, limit: 5) {
      id,
      place,
      startTime,
      place,
      date,
      link,
      sportType,
      short,
      left {
       	id,
        avatar,
        fullName,
    	},
      right {
       	id,
        avatar,
        fullName,
      },
    },
  }
`;

@Component({
  name: 'volleyball',
  components: {
    Banners,
    TeamMenu,
    News,
    Footer,
    Afisha,
    LayoutBlock,
    MediaAlbums,
    Header,
    ResultsTable,
    TournamentTable,
  },
})
export default class VolleyballM extends Vue {
  @Prop() private link!: string;
  private matches: IMatch[] = [];
  private results: IMatch[] = [];
  private news: INewsItem[] = [];
  private sportEvents: ISportEvent[] = [];
  private scoreMap: {[key: string]: IShortLong} = {
    games: {short: 'и', long: 'количество игр'},
    points: {short: 'о', long: 'количество очков'},
  };
  private data() {
    return {
      name: '',
    };
  }
  private getScore = (stat: ITeamStats, key: string) => {
    return stat && stat[key as keyof ITeamStats] || 0;
  }
  private async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: AllQuery,
    });
    if (data) {
      this.matches = data.matches;
      this.sportEvents = data.sportEvents;
      this.results = data.results.filter((result: IMatch) => (
        typeof result.leftScore !== 'undefined' && typeof result.rightScore !== 'undefined'
      ));
      this.news = data.news;
    }
    loadSeo({title: `СК "Держава" ТГУ — Волейбол (Мужчины)`, keywords: '', description: ''} as ISeo);
  }
}
