








































































// @ is an alias to /src
import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import { IMatch, IShortLong, ISportEvent, ITeam, ITeamStats } from '@/types/teams';
import { loadSeo } from '@/utils/pages';
import { ISeo } from '@/types/pages';
import TeamMenu from '@/components/TeamMenu.vue';
import Team from '@/components/Team.vue';
import TournamentTable from '@/components/TournamentTable.vue';
import { getSeason, getSeasonString } from '@/utils/team';


export interface IPlayOff {
  id: string;
  avatar: string;
  year: number;
  description: string;
  sportType: string;
  isActive: boolean;
  events: IPlayOffEvent[];
}

export interface IPlayOffEvent {
  id: string;
  position: number;
  stage: string;
  left: ITeam;
  right: ITeam;
  scores: IPlayOffScore[];
}

export interface IPlayOffScore {
  id: string;
  leftScore: string;
  rightScore: string;
}

const AllQuery = `
  query {
    stats: hockeyTournamentStatistics(season: ":season") {
      id,
      deactivated,
      season,
      games,
      wins,
      winsPoints,
      losesPoints,
      loses,
      goalsFrom,
      goalsTo,
      points,
      lastFive,
      teamName,
    },
    sportEvents(types: "hockey")  {
      id,
      img,
      link,
    },
    matches(types: "hockey", isAfisha: true, limit: 5) {
      id,
      place,
      startTime,
      place,
      date,
      sportType,
      short,
      left {
       	id,
       	avatar,
        fullName,
    	},
      right {
       	id,
       	avatar,
        fullName,
      },
    },
    playOff(sportType: "hockey") {
      id,
      avatar,
      year,
      description,
      sportType,
      isActive,
      events {
        id,
        position,
        stage,
        left {
          avatar,
          fullName
        },
        right {
          avatar,
          fullName
        },
        scores {
          id,
          leftScore,
          rightScore,
        }
      }
    },
  }
`;

@Component({
  name: 'hockeyStat',
  components: {
    Team,
    TeamMenu,
    News,
    Footer,
    Afisha,
    LayoutBlock,
    TournamentTable,
    Header,
  },
})
export default class HockeyStat extends Vue {
  private stats: ITeamStats[] = [];
  private get seasonString() {
    return getSeasonString();
  }
  private matches: IMatch[] = [];
  private sportEvents: ISportEvent[] = [];
  private playOff: IPlayOff | null = null;
  private titleMap = {
    GOALKEEPER: 'Вратари',
    DEFENDER: 'Защитники',
    FORWARD: 'Нападающие',
  };
  private slicePlayOffMap: {[key: string]: number} = {
    A_1_4: 4,
    A_1_2: 2,
    A_1_1: 1,
  };
  private scoreMap: {[key: string]: IShortLong} = {
    games: {short: 'и', long: 'количество игр'},
    wins: {short: 'в', long: 'выигрыши'},
    winsPoints: {short: 'во', long: 'выигрыш (ОТ или Б)'},
    losesPoints: {short: 'по', long: 'поражение (ОТ или Б)'},
    loses: {short: 'п', long: 'поражения'},
    goals: {short: 'ш', long: 'забитые/пропущенные шайбы'},
    points: {short: 'о', long: 'количество очков'},
    lastFive: {short: 'последние 5', long: ''},
  };
  private data() {
    return {
      name: '',
    };
  }
  private async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: gql(AllQuery.replace(':season', getSeason())),
    });
    if (data) {
      this.playOff = data.playOff;
      if (Array.isArray(data.stats)) {
        this.stats = data.stats;
        this.stats.sort((a, b) => b.points - a.points);
      }
      this.matches = data.matches;
    }
    loadSeo({title: 'СК "Держава" ТГУ — Хоккей | Турнирная таблица', keywords: '', description: ''} as ISeo);
  }
  private getPlayOffGames = (type: string, events: IPlayOffEvent[]): Array<(IPlayOffEvent | null)> => {
    const max = this.slicePlayOffMap[type] || 0;
    const result = events.filter(({stage}) => stage === type);
    return result.length > max
      ? result.slice(0, this.slicePlayOffMap[type] || 0)
      : [...result, ...new Array(max - result.length).fill(null)];
  }
  private getScore = (stat: ITeamStats, key: string) => {
    if (key === 'goals') {
      const { goalsFrom = 0, goalsTo = 0 } = stat;
      return `${goalsFrom}-${goalsTo}`;
    } else if (key === 'lastFive') {
      const innerPoints = stat.lastFive.split('|').slice(0, 5).map((point) => point === '1'
        ? `<span class="win">В</span>`
        : `<span class="lose">П</span>`,
      ).join('');

      return `<div class="d-flex justify-content-center">${innerPoints}</div>`;
    } else {
      return stat && stat[key as keyof ITeamStats] || 0;
    }
  }
}
