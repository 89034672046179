import { INewsItem } from '@/types/news';

export const getNewsLink = (item: INewsItem) => {
  return `/news/${item.date}-${item.slug}/`;
};

interface IFormatDateOptions {
  includeYear?: boolean;
}

export const formatDate = (date: string, { includeYear = true }: IFormatDateOptions = {}) => {
  const options = Object.assign({ day: 'numeric', month: 'long' }, includeYear && {year: 'numeric'});
  let result = new Date(date).toLocaleString('ru', options);
  if (includeYear) {
    result = result.slice(0, -3);
  }

  return result;
};


export const formatTime = (time: string) => time.slice(0, -3).replace(':', '-');
