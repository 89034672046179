















































// @ is an alias to /src
import { Component, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import { groupBy } from 'lodash';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import { IHockeyPlayer, IHockeyStatistics, IMatch, IPlayer, IShortLong, ITeamStats } from '@/types/teams';
import { loadSeo } from '@/utils/pages';
import { IIndexable, ISeo } from '@/types/pages';
import TeamMenu from '@/components/TeamMenu.vue';
import Team, { IGroupedPlayers } from '@/components/Team.vue';
import TournamentTable from '@/components/TournamentTable.vue';
import { getSeason, getSeasonString } from '@/utils/team';

const AllQuery = `
  query {
    players: hockeyPlayers {
      id,
      grip,
      number,
      position,
      unitFull,
      person {
        id,
        firstName,
        lastName,
        avatar,
        birthday,
        weight,
        height,
      },
      statistics {
        id,
        season,
        allWins,
        allPoints,
        missedGoals,
        missedAvg,
        penalty,
        totalGoals,
        totalPass,
        totalGames,
      },
    },
  }
`;

@Component({
  name: 'hockeyStat',
  components: {
    Team,
    TeamMenu,
    News,
    Footer,
    Afisha,
    LayoutBlock,
    TournamentTable,
    Header,
  },
})
export default class HockeyPersonalStat extends Vue {
  private stats: ITeamStats[] = [];
  private get seasonString() {
    return getSeasonString();
  }
  private players: IGroupedPlayers = {};
  private matches: IMatch[] = [];
  private titleMap = {
    FORWARD: 'Нападающие',
    DEFENDER: 'Защитники',
    GOALKEEPER: 'Вратари',
  };
  private statMap = {
    BOMB: 'Бомбардиры',
    SNIPER: 'Снайперы',
    ASSIST: 'Ассистенты',
  };
  private statGoalkeeperMap = {
    GOALKEEPER: 'Вратари',
  };
  private statPositionMap = {
    FORWARD: this.statMap,
    DEFENDER: this.statMap,
    GOALKEEPER: this.statGoalkeeperMap,
  };
  private scoreMap: {[key: string]: IShortLong} = {
    totalGames: {short: 'и', long: 'количество игр'},
    totalGoals: {short: 'ш', long: 'количество забитых шайб'},
    totalPass: {short: 'а', long: 'количество передач'},
    allPoints: {short: 'о', long: 'количество очков'},
    penalty: {short: 'шм', long: 'штрафные минуты'},
  };
  private scoreMapGoalkeeper: {[key: string]: IShortLong} = {
    totalGames: {short: 'и', long: 'количество игр'},
    missedGoals: {short: 'ш', long: 'пропущено шайб'},
    penalty: {short: 'шм', long: 'штрафные минуты'},
    allWins: {short: 'п', long: 'количество побед'},
  };
  private scorePositionMap = {
    FORWARD: this.scoreMap,
    DEFENDER: this.scoreMap,
    GOALKEEPER: this.scoreMapGoalkeeper,
  };
  private async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: gql(AllQuery),
    });
    if (data) {
      this.players = groupBy(data.players, 'position') as IGroupedPlayers;
    }
    loadSeo({title: 'СК "Держава" ТГУ — Хоккей | Личная статистика', keywords: '', description: ''} as ISeo);
  }
  private getScore = (stat: ITeamStats, key: string) => {
    return stat && stat[key as keyof ITeamStats] || 0;
  }

  private getPlaceClassName(place: number) {
    return ['b-tournament__place', place < 1 && 'b-tournament__place--first'].filter(Boolean);
  }

  private getFullName({person: {firstName, lastName}}: IHockeyPlayer) {
    return [firstName, lastName].join(' ');
  }

  private getCurrentStat = (player: IHockeyPlayer, season: string) => {
    return player.statistics.find((stat) => stat.season === season);
  }

  private getStat = (key: string, players: IHockeyPlayer[]) => {
    const season = `A_${getSeason()}`;
    const result = [...players.map((player) => ({...player, currentStat: this.getCurrentStat(player, season)}))];
    const keyMap: IIndexable = {
      BOMB: 'allPoints',
      SNIPER: 'totalGoals',
      ASSIST: 'totalPass',
      GOALKEEPER: 'allWins',
    };
    const currentPoints = keyMap[key] as keyof IHockeyStatistics;
    result.sort((a, b) => {
      if (!a.currentStat || !b.currentStat) {
        return -1;
      }
      return Number(b.currentStat[currentPoints]) - Number(a.currentStat[currentPoints]);
    });
    // }

    return result;
  }

  private getPlayerLink(player: IPlayer) {
    return `/hockey/sostav/player/${player.id}/`;
  }
}
