










































































// @ is an alias to /src
import { Component, Prop, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import { IHockeyPlayer, IHockeyStatistics, IMatch, IShortLong, ISportEvent, ITeamStats } from '@/types/teams';
import TeamMenu from '@/components/TeamMenu.vue';
import { INewsItem } from '@/types/news';
import { loadSeo } from '@/utils/pages';
import { ISeo } from '@/types/pages';
import TournamentTable from '@/components/TournamentTable.vue';
import ResultsTable from '@/components/ResultsTable.vue';
import Leaders from '@/components/Leaders.vue';
import MediaAlbums from '@/components/MediaAlbums.vue';
import Banners from '@/components/Banners.vue';
import { getSeason } from '@/utils/team';

const AllQuery = `
  query {
    news(category: "Хоккей") {
      title,
      date,
      short,
      show,
      slug,
      images {
        id,
        image,
        title,
      },
      tags {
        id,
        name,
      },
    },
    sportEvents(types: "HOCKEY")  {
      id,
      img,
      link,
    },
    results: matches(types: "HOCKEY", hasResults: true, limit: 5) {
      id,
      place,
      date,
      sportType,
      short,
      left {
       	id,
        fullName,
    	},
      right {
       	id,
        fullName,
      },
      leftScore,
      rightScore,
    },
    matches(types: "hockey", isAfisha: true, limit: 5) {
      id,
      place,
      startTime,
      place,
      date,
      link,
      sportType,
      short,
      left {
       	id,
        avatar,
        fullName,
    	},
      right {
       	id,
        avatar,
        fullName,
      },
    },
    hockeyPlayers {
      id,
      grip,
      number,
      position,
      history {
        id,
        dateStart,
        dateEnd,
        student,
        team {
          avatar,
          name,
        }
      },
      person {
        id,
        firstName,
        lastName,
        avatar,
      },
      statistics {
        id,
        season,
        allWins,
        allPoints,
        missedGoals,
        missedAvg,
        penalty,
        totalGoals,
        totalPass,
        totalGames,
      },
    },
    stats: hockeyTournamentStatistics(season: ":season", limit: 5) {
      id,
      season,
      games,
      wins,
      winsPoints,
      losesPoints,
      loses,
      goalsFrom,
      goalsTo,
      points,
      lastFive,
      teamName,
    },
  }
`;

@Component({
  name: 'hockey',
  components: {
    Banners,
    Leaders,
    TournamentTable,
    TeamMenu,
    News,
    Footer,
    Afisha,
    LayoutBlock,
    Header,
    ResultsTable,
    MediaAlbums,
  },
})
export default class Hockey extends Vue {
  @Prop() private link!: string;
  private matches: IMatch[] = [];
  private results: IMatch[] = [];
  private news: INewsItem[] = [];
  private sportEvents: ISportEvent[] = [];
  private stats: ITeamStats[] = [];
  private scoreMap: {[key: string]: IShortLong} = {
    games: {short: 'и', long: 'количество игр'},
    points: {short: 'о', long: 'количество очков'},
  };
  private dataLoaded = false;
  private hockeyPlayers: IHockeyPlayer[] | null = null;
  private statMap = {
    BOMB: 'Бомбардиры',
    SNIPER: 'Снайперы',
    ASSIST: 'Ассистенты',
    GOALKEEPER: 'Вратари (КН)',
  };
  private data() {
    return {
      name: '',
      propResults: this.results,
    };
  }
  private getScore = (stat: ITeamStats, key: string) => {
    return stat && stat[key as keyof ITeamStats] || 0;
  }
  private getScores = ({allPoints, allWins, totalPass, totalGoals}: IHockeyStatistics, type: string) => {
    if (type === 'GOALKEEPER') {
      return `${allWins} (${totalGoals}+${totalPass})`;
    } else {
      return `${allPoints} (${totalGoals}+${totalPass})`;
    }
  }
  private getAllPoints = (stats: {allPoints?: number, totalGoals: number, totalPass: number}) => {
    const { allPoints, totalGoals, totalPass } = stats;

    return allPoints || (totalPass + totalGoals);
  }
  private async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: gql(AllQuery.replace(':season', getSeason())),
    });
    if (data) {
      this.matches = data.matches;
      this.hockeyPlayers = data.hockeyPlayers;
      this.sportEvents = data.sportEvents;
      this.results = data.results.filter((result: IMatch) => (
        typeof result.leftScore !== 'undefined' && typeof result.rightScore !== 'undefined'
      ));
      this.news = data.news;
      if (Array.isArray(data.stats)) {
        this.stats = data.stats;
        this.stats.sort((a, b) => b.points - a.points);
        this.stats = this.stats.slice(0, 5);
      }
    }
    this.$forceUpdate();
    loadSeo({title: `СК "Держава" ТГУ — Хоккей`, keywords: '', description: ''} as ISeo);
  }
}
