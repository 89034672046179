





















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IPageImage } from '@/types/pages';

@Component
export default class InfraCarousel extends Vue {
  @Prop() private images!: IPageImage[];
}
Vue.component('infra-carousel', InfraCarousel);
