















import { Component, Prop, Vue } from 'vue-property-decorator';
import { IMenuItem } from '@/types/menu';

const VOLLEYBALL_EXCLUDED_TITLES = ['Личная статистика', 'Турнирная таблица'];

@Component
export default class TeamMenu extends Vue {
  @Prop() private type!: string;
  private typeNameMap: {[key: string]: string} = {
    'hockey': 'Хоккей',
    'volleyball-f': 'Волейбол (Ж)',
    'volleyball-m': 'Волейбол (М)',
    'football': 'Футбол',
    'basketball-f': 'Баскетбол (Ж)',
    'basketball-m': 'Баскетбол (М)',
  };

  private menu: IMenuItem[] = [
    {title: `${this.typeNameMap[this.type]}`, link: `/${this.type}/`},
    {title: 'История', link: `/${this.type}/istoriya-${this.type.replace(/-(f|m)$/, '')}/`},
    {title: 'Состав', link: `/${this.type}/sostav/`},
    {title: 'Турнирная таблица', link: `/${this.type}/tablica/`},
    {title: 'Личная статистика', link: `/${this.type}/statistica/`},
  ];

  private getMenu() {
    const { path } = this.$route;
    if (path.startsWith('/volleyball-')) {
      return this.menu.filter(({title}) => !VOLLEYBALL_EXCLUDED_TITLES.includes(title));
    }

    return this.menu.slice();
  }
}
