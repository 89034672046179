



































import gql from 'graphql-tag';
import { Component, Prop, Vue } from 'vue-property-decorator';
import Header from '../components/Header.vue';
import LayoutBlock from '../components/LayoutBlock.vue';
import Footer from '../components/Footer.vue';
import { IPage, IPageContent } from '@/types/pages';
import { getContentByType, loadSeo } from '@/utils/pages';
import MainLogo from '@/components/MainLogo.vue';
import InfraCarousel from '@/components/InfraCarousel.vue';

const PageQueryString = `
  query {
     pageInfrastructure(link: ":link") {
      id,
      status,
      seo {
        id,
        title,
        description,
        keywords,
      },
  		items {
        id,
        title,
        text,
        contacts {
        	id,
        	img,
        	text,
      	},
        images {
          id,
          image,
          title,
        },
      },
      content(types: "top_content,middle_content,bottom_content") {
        type,
        body,
      },
    }
  }
`;

@Component({
  name: 'infrastructure',
  components: {
    InfraCarousel,
    MainLogo,
    Footer,
    LayoutBlock,
    Header,
  },
})

export default class Infrastructure extends Vue {
  @Prop() private link!: string;
  private page: IPage | null = null;
  private getContentByType = getContentByType;
  private data() {
    return {
      name: '',
    };
  }
  private async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: gql(PageQueryString.replace(':link', 'sportivnaya-infrastruktura')),
    });
    this.page = data.pageInfrastructure;
    if (data.page.seo) {
      loadSeo(data.page.seo);
    }
  }

  private replaceInfraGallery = (text: string) => {
    return text.replace(/<img\s*?src="(.*?)"\s*?alt="(.*?)"\s*?data-type="carousel"\s*?\/>/ig, '||$1|$2' + '||')
      .replace(/\|\|(.+)\|\|/ig, '<infra-carousel photos="$1" />').replace(/\|\|\|\|/ig, '||');
  }
}
