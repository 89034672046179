export const getSeason = () => (window as any).__derzhavaConfig && (window as any).__derzhavaConfig.season || '29';

export const getSeasonRange = (season?: string) => {
  const n = Number(season || getSeason()) || 19;

  return [n, n + 1];
};

export const getSeasonString = (season?: string) => {
  const [start, end] = getSeasonRange(season);

  return `Сезон ${start}/${end}`;
};
