<template>
  <div class="home">
    <Header />
    <div class="container__wrapper">
      <div class="container">
        <div class="row">
          <div class="col-7">
            <LayoutBlock title="Афиша">
              <Afisha :matches="matches" :events="sportEvents"/>
            </LayoutBlock>
          </div>
          <div class="col-5 pl-5">
            <LayoutBlock title="Результаты">
              <ResultsTable :results="results"></ResultsTable>
            </LayoutBlock>
          </div>
        </div>
        <div class="mt-m6">
          <MediaAlbums pages="glavnaya" limit=""></MediaAlbums>
        </div>
      </div>
    </div>
    <Banners>
      <div class="container__wrapper">
        <div class="container">
          <div class="row">
            <div class="col-12">
              <LayoutBlock title="Новости">
                <News :news="fpNews" isFirstPage="true"/>
              </LayoutBlock>
            </div>
          </div>
        </div>
      </div>
    </Banners>
    <div class="container__wrapper">
      <div class="row b-rector">
        <div class="col-6">
          <div class="b-rector__img"></div>
        </div>
        <div class="col-6">
          <div class="b-rector__quote">
            <div class="b-rector__quote-text">Наши задачи ясны и понятны, наши цели при настойчивой и упорной работе обязательно осуществимы. Мы делаем всё возможное для достижения высоких спортивных результатов в настоящем и с оптимизмом смотрим в будущее</div>
            <div class="b-rector__quote-name">Стромов Владимир Юрьевич</div>
            <div class="b-rector__quote-post">ректор ТГУ имени Г.Р. Державина</div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import gql from 'graphql-tag';
import Header from '../components/Header';
import LayoutBlock from '../components/LayoutBlock';
import MediaAlbums from '../components/MediaAlbums';
import Afisha from '../components/Afisha';
import Footer from '../components/Footer';
import News from '../components/News';
import { getContentByType, loadSeo } from '../utils/pages';
import ResultsTable from '../components/ResultsTable';
import Banners from '../components/Banners';


const AllQuery = gql`
  query {
    matches(isAfisha: true, isFp: true, limit: 5) {
      id,
      place,
      startTime,
      place,
      date,
      link,
      sportType,
      short,
      left {
       	id,
        avatar,
        fullName,
    	},
      right {
       	id,
        avatar,
        fullName,
      },
    },
    sportEvents(isFp: true) {
      id,
      img,
      link,
    },
    results: matches(hasResults: true, isFp: true, limit: 5) {
      id,
      place,
      date,
      sportType,
      short,
      left {
       	id,
        fullName,
    	},
      right {
       	id,
        fullName,
      },
      leftScore,
      rightScore,
    },
    fpNews {
      title,
      date,
      short,
      show,
      slug,
      images {
        id,
        image,
        title,
      },
      tags {
        id,
        name,
      },
    },
    page(id: 1) {
      id,
      status,
      seo {
        id,
        title,
        description,
        keywords,
      },
    }
  }
`;

export default {
  name: 'home',
  components: {
    Banners,
    ResultsTable,
    News,
    Footer,
    Afisha,
    LayoutBlock,
    Header,
    MediaAlbums,
  },
  page: null,
  data() {
    return {
      name: '',
      description: '',
      // Initialize your apollo data
      tasks: '',
      page: {},
      matches: [],
      sportEvents: [],
      results: [],
      fpNews: [],
    };
  },
  methods: {
    getContentByType,
  },
  async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: AllQuery,
    });
    if (data) {
      this.matches = data.matches;
      this.sportEvents = data.sportEvents;
      this.fpNews = data.fpNews;
      this.page = data.page;
      this.results = data.results.filter((result) => (
        typeof result.leftScore !== 'undefined' && typeof result.rightScore !== 'undefined'
      ));
      if (data.page.seo) {
        loadSeo(data.page.seo);
      }
    }
  },
};
</script>

<style lang="scss">
  .container__wrapper {
    margin: auto;
    max-width: 1200px;
    box-shadow: 0 24px 23px rgba(35, 36, 94, 0.56);
  }

  .b-support__banner {
    background: right no-repeat;
    display: block;
  }
  .b-support {
    background-color: #24295d;
    &--first {
      height: 401px;
    }
    &--second {
      height: 195px;
      background: linear-gradient(to right, #23245E, #7B0A06 100%);
    }

    &__wrapper {
      max-width: 1200px;
      height: 100%;
      margin: auto;
    }

    &__banner {
      height: 100%;
    }
  }

  .b-rector {
    padding-top: 25px;
    &__img {
      width: 468px;
      height: 562px;
      background: url('../assets/img/rector.png') right no-repeat;
    }
    &__quote {
      display: flex;
      flex-flow: column;
      padding: 35px 8px;
      width: 372px;
      height: 356px;
      background: white;
      margin: auto;
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;

      &::before {
        position: absolute;
        top: -35px;
        left: 20px;
        content: '';
        background: url('../assets/img/kovich_left.svg') center no-repeat;
        background-size: cover;
        width: 69px;
        height: 69px;
      }

      &::after {
        position: absolute;
        bottom: -35px;
        right: 20px;
        content: '';
        background: url('../assets/img/kovich_right.svg') center no-repeat;
        background-size: cover;
        width: 69px;
        height: 69px;
      }

      &-title {
        letter-spacing: -1px;
        color: #262760;
        font-size: 20px;
        line-height: 24px;
        font-weight: 700;
        text-transform: uppercase;
      }
      &-subtitle {
        letter-spacing: -1px;
        color: #262760;
        font-size: 17px;
        line-height: 20px;
        font-weight: 400;
        text-transform: uppercase;
      }
      &-text {
        margin: 20px 35px auto;
        font-size: 19px;
        line-height: 1.3;
        color: #898989;
      }
      &-name {
        font-size: 16px;
        line-height: 19px;
        color: #262760;
        font-weight: 700;
      }
      &-post {

      }
    }
  }
  .b-results {
    table {
      text-transform: uppercase;
      width: 100%;

      tr {
        display: flex;
        width: 100%;
        height: 30px;
        align-items: center;

        td {
          display: flex;
          width: 50%;
        }
      }

      tr:nth-child(2n) {
        font-size: 14px;
        background-color: white;
        color: #5B5B5B;

        td:first-child {
          span:last-child {
            margin-left: auto;
            margin-right: -16px;

            &::after {
              content: '-';
            }
          }
        }

        td:last-child {
          span:first-child {
            margin-right: auto;
            margin-left: -16px;
          }
        }
      }
      tr:nth-child(2n + 1) {
        line-height: 30px;
        font-size: 17px;
        background-color: #262880;
        color: white;
        font-weight: 700;

        td:last-child {
          font-weight: 400;
        }
      }
      td {
        padding: 0 16px;
      }
      td:last-child {
        text-align: right;
        justify-content: flex-end;
      }
    }
  }
</style>
