




























































/* tslint:disable:no-any */
import { Component, Prop, Vue } from 'vue-property-decorator';
import { IMatch, ISportEvent } from '@/types/teams';

@Component
export default class Afisha extends Vue {
  private static data() {
    return {
      slide: 0,
      sliding: null,
    };
  }
  private CUSTOM_BG_MAP = {
    VOLLEYBALL_F: '/static/match_valley.png',
    VOLLEYBALL_M: '/static/match_valley.png',
    FOOTBALL: '/static/match_football.png',
    BASKETBALL_F: '/static/match_basketball.png',
    BASKETBALL_M: '/static/match_basketball.png',
  };
  private SPORT_MAP = {
    HOCKEY: 'Хоккей',
    VOLLEYBALL_F: 'Волейбол (Д)',
    VOLLEYBALL_M: 'Волейбол (Ю)',
    FOOTBALL: 'Футбол',
    BASKETBALL_F: 'Баскетбол (Д)',
    BASKETBALL_M: 'Баскетбол (Ю)',
  };
  @Prop() private matches!: IMatch[];
  @Prop() private events!: ISportEvent[];

  private sliding: boolean = false;
  private onSlideStart(slide: any) {
    (this as any).sliding = true;
  }
  private onSlideEnd(slide: any) {
    (this as any).sliding = false;
  }
  private formatDate = (date: string) =>
    new Date(date).toLocaleString('ru', { day: 'numeric', month: 'long', year: 'numeric' }).slice(0, -3)
}
