






























  // @ is an alias to /src
  import { Component, Vue } from 'vue-property-decorator';
  import gql from 'graphql-tag';
  import Header from '@/components/Header.vue';
  import LayoutBlock from '@/components/LayoutBlock.vue';
  import Afisha from '@/components/Afisha.vue';
  import Footer from '@/components/Footer.vue';
  import News from '@/components/News.vue';
  import { IMatch, IShortLong, ISportEvent, ITeamStats } from '@/types/teams';
  import { loadSeo } from '@/utils/pages';
  import { IIndexable, ISeo } from "@/types/pages";
  import TeamMenu from '@/components/TeamMenu.vue';
  import Team from '@/components/Team.vue';
  import TournamentTable from '@/components/TournamentTable.vue';
  import Result from '@/components/Result.vue';

  const AllQuery = `
  query {
    sportEvents(types: ":type")  {
      id,
      img,
      link,
    },
    results: matches(types: ":type", hasResults: true) {
      id,
      place,
      startTime,
      place,
      date,
      sportType,
      short,
      left {
       	id,
       	avatar,
        fullName,
    	},
      right {
       	id,
       	avatar,
        fullName,
      },
      leftScore,
      rightScore,
    },
    matches(types: ":type", isAfisha: true, limit: 5) {
      id,
      place,
      startTime,
      place,
      date,
      sportType,
      short,
      left {
       	id,
       	avatar,
        fullName,
    	},
      right {
       	id,
       	avatar,
        fullName,
      },
    },
  }
`;

  interface IGameResult {
    [key: string]: {
      letter: string;
      className: string;
    };
  }

  @Component({
    name: 'results',
    components: {
      Team,
      TeamMenu,
      News,
      Footer,
      Afisha,
      LayoutBlock,
      TournamentTable,
      Header,
      Result,
    },
  })
  export default class Results extends Vue {
    private stats: ITeamStats[] = [];
    private sportEvents: ISportEvent[] = [];
    private matches: IMatch[] = [];
    private results: IMatch[] = [];
    private SPORT_TYPE_MAP = {
      HOCKEY: 'Хоккей',
      VOLLEYBALL: 'Волейбол',
      FOOTBALL: 'Футбол',
      BASKETBALL: 'Баскетбол',
    };
    private linkTypeMap: IIndexable = {
      '/hockey/': 'hockey',
      '/football/': 'football',
      '/basketball-m/': 'basketball-m',
      '/basketball-f/': 'basketball-f',
      '/volleyball-m/': 'volleyball-m',
      '/volleyball-f/': 'volleyball-f',
    };

    private data() {
      return {
        name: '',
      };
    }

    private get type() {
      const { path } = this.$route;

      for (const key of Object.keys(this.linkTypeMap)) {
        if (path.includes(key)) {
          return this.linkTypeMap[key];
        }
      }

      return '';
    }

    private async mounted() {
      window.scroll(0, 0);
      const { path } = this.$route.params;
      const { data } = await this.$apollo.query({
        query: gql(AllQuery.replace(/:type/ig, path.replace(/-/g, '_'))),
      });
      if (data) {
        this.results = data.results;
        this.sportEvents = data.sportEvents;
        this.matches = data.matches;
      }
      loadSeo({title: 'СК "Держава" ТГУ — Результаты | Турнирная таблица', keywords: '', description: ''} as ISeo);
    }
  }
