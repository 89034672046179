













































































// @ is an alias to /src
import { Component, Prop, Vue } from 'vue-property-decorator';
import gql from 'graphql-tag';
import Header from '@/components/Header.vue';
import LayoutBlock from '@/components/LayoutBlock.vue';
import Afisha from '@/components/Afisha.vue';
import Footer from '@/components/Footer.vue';
import News from '@/components/News.vue';
import { ICommonStat, IFootballPlayer, IFootballStatistics, IMatch, IShortLong, ISportEvent, ITeamStats } from '@/types/teams';
import TeamMenu from '@/components/TeamMenu.vue';
import { INewsItem } from '@/types/news';
import { loadSeo } from '@/utils/pages';
import { IIndexable, ISeo } from '@/types/pages';
import MediaAlbums from '@/components/MediaAlbums.vue';
import TournamentTable from '@/components/TournamentTable.vue';
import Leaders from '@/components/Leaders.vue';
import Banners from '@/components/Banners.vue';
import ResultsTable from '@/components/ResultsTable.vue';
import { getSeason } from '@/utils/team';

const AllQuery = `
  query {
    news(category: "Футбол") {
      title,
      date,
      short,
      show,
      slug,
      images {
        id,
        image,
        title,
      },
      tags {
        id,
        name,
      },
    },
    sportEvents(types: "FOOTBALL")  {
      id,
      img,
      link,
    },
    results: matches(types: "FOOTBALL", hasResults: true, limit: 5) {
      id,
      place,
      date,
      sportType,
      short,
      left {
       	id,
        fullName,
    	},
      right {
       	id,
        fullName,
      },
      leftScore,
      rightScore,
    },
    stats: footballTournamentStatistics(season: ":season") {
      id,
      season,
      games,
      points,
      teamName,
    },
    players: footballPlayers {
      id,
      number,
      position,
      person {
        id,
        firstName,
        lastName,
        avatar,
      },
      statistics {
        id,
        season,
        matches,
        totalGoals,
        passes,
        redCards,
        yellowCards,
      },
    },
    matches(types: "football", isAfisha: true, limit: 5) {
      id,
      place,
      startTime,
      place,
      date,
      link,
      sportType,
      short,
      left {
       	id,
        avatar,
        fullName,
    	},
      right {
       	id,
        avatar,
        fullName,
      },
    },
  }
`;

@Component({
  name: 'football',
  components: {
    Banners,
    TeamMenu,
    News,
    Footer,
    Afisha,
    LayoutBlock,
    Header,
    MediaAlbums,
    TournamentTable,
    Leaders,
    ResultsTable,
  },
})
export default class Football extends Vue {
  @Prop() private link!: string;
  private type = 'football';
  private matches: IMatch[] = [];
  private results: IMatch[] = [];
  private news: INewsItem[] = [];
  private players: IFootballPlayer[] | null = null;
  private sportEvents: ISportEvent[] = [];
  private stats: ITeamStats[] = [];
  private scoreMap: {[key: string]: IShortLong} = {
    games: {short: 'и', long: 'количество игр'},
    points: {short: 'о', long: 'количество очков'},
  };
  private SPORT_MAP = {
    HOCKEY: 'Хоккей',
    VOLLEYBALL_ZH: 'Волейбол (Д)',
    VOLLEYBALL_M: 'Волейбол (Ю)',
    FOOTBALL: 'Футбол',
    BASKETBALL_F: 'Баскетбол (Д)',
    BASKETBALL_M: 'Баскетбол (Ю)',
  };
  private keyMap: IIndexable = {
    BOMB: 'totalGoals',
    ASSIST: 'passes',
    GOALS_PASSES: 'allPoints',
  };
  private positionMap = {
    FORWARD: 'Нападающий',
    DEFENDER: 'Защитник',
    MID: 'Полузащитник',
    GOALKEEPER: 'Вратарь',
  };
  private statMap = {
    BOMB: 'Бомбардиры',
    ASSIST: 'Ассистенты',
    GOALS_PASSES: 'Гол+пас',
  };
  private scoreLeadersMap: {[key: string]: IShortLong} = {
    totalGoals: {short: 'гз', long: 'голов забито'},
    passes: {short: 'п', long: 'пас'},
    goalsPasses: {short: 'гп', long: 'гол+пас'},
  };
  private scorePositionMap = {
    FORWARD: this.scoreLeadersMap,
    DEFENDER: this.scoreLeadersMap,
    MID: this.scoreLeadersMap,
  };
  private data() {
    return {
      name: '',
    };
  }
  private getScore = (stat: ITeamStats, key: string) => {
    return stat && stat[key as keyof ITeamStats] || 0;
  }
  private getScores = (stats: IFootballStatistics | void, type: string) => {
    if (!stats) {
      return '';
    }
    const { passes, totalGoals } = stats;
    return `${totalGoals + passes} (${totalGoals}+${passes})`;
  }
  private updateCurrentStat = (stat?: ICommonStat): IFootballStatistics => {
    const result = stat as unknown as IFootballStatistics;
    if (result) {
      result.allPoints = result.passes + result.totalGoals;
    }

    return result;
  }

  private async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: gql(AllQuery.replace(':season', getSeason())),
    });
    if (data) {
      this.matches = data.matches;
      this.sportEvents = data.sportEvents;
      this.results = data.results.filter((result: IMatch) => (
        typeof result.leftScore !== 'undefined' && typeof result.rightScore !== 'undefined'
      ));
      this.news = data.news;
      this.players = data.players;
      if (Array.isArray(data.stats)) {
        this.stats = data.stats;
        this.stats.sort((a, b) => b.points - a.points);
        this.stats = this.stats.slice(0, 5);
      }
    }
    loadSeo({title: `СК "Держава" ТГУ — Футбол`, keywords: '', description: ''} as ISeo);
  }
}
