






import { Component, Vue } from 'vue-property-decorator';
import HelloWorld from './components/HelloWorld.vue';
import HeaderMenu from './components/HeaderMenu.vue';
import './App.scss';

@Component({
  components: {
    HelloWorld,
    HeaderMenu,
  },
})
export default class App extends Vue {
  public mounted() {
    document.body.classList.remove('loading');
  }
}
