







































import gql from 'graphql-tag';
import { Component, Vue } from 'vue-property-decorator';
import Header from '../components/Header.vue';
import LayoutBlock from '../components/LayoutBlock.vue';
import Footer from '../components/Footer.vue';
import { IPage } from '@/types/pages';
import { getContentByType, loadSeo } from '@/utils/pages';
import MainLogo from '@/components/MainLogo.vue';

const PageQueryString = `
  query {
     page: pedestal {
      id,
      title,
      topContent,
      widgetCode,
      persons {
        id,
        title,
        info,
        img,
        position,
      },
      seo {
        id,
        title,
        description,
        keywords,
      },
    }
  }
`;

interface IPedestalPerson {
  id: string;
  title: string;
  info: string;
  img: string;
  position: number;
}

interface IPedestalPage extends IPage {
  title: string;
  topContent: string;
  widgetCode: string;
  persons: IPedestalPerson[];
}

/* tslint:disable:next-lint:max-line-length */
const defaultTopContent = 'Номинация «Лучший спортсмен университета» (по итогам 4-го квартала 2019 года).\nВ данной номинации за право быть лучшим спортсменом университета по спортивным достижениям, показанным в 4-ом квартале 2019 года (октябрь-декабрь) соревнуются  4 студента. Это представители не только разных видов спорта, но и представители  разных институтов и факультетов нашего университета.';
const placeColorMap: {[key: number]: string} = {
  1: '#FBB03B',
  2: '#7F7F7F',
  3: '#753D0C',
};

@Component({
  name: 'pedestal',
  components: {
    MainLogo,
    Footer,
    LayoutBlock,
    Header,
  },
})
export default class Pedestal extends Vue {
  private page: IPedestalPage | null = null;
  private getContentByType = getContentByType;
  private data() {
    return {
      name: '',
    };
  }
  private async mounted() {
    window.scroll(0, 0);
    const { data } = await this.$apollo.query({
      query: gql(PageQueryString),
    });

    if (data.page) {
      const { widgetCode } = data.page;
      if (widgetCode) {
        const vkScript = document.createElement('script');
        vkScript.setAttribute('src', 'https://vk.com/js/api/openapi.js?165');
        /* tslint:disable-next-line:no-eval */
        vkScript.onload = () => eval(widgetCode);
        document.head.appendChild(vkScript);
      }
      this.page = data.page;
      if (this.page && !this.page.topContent) {
        this.page.topContent = defaultTopContent;
      }
      if (data.page.seo) {
        loadSeo(data.page.seo);
      }
    }
  }

  private getColor(place: number) {
    return placeColorMap[place] || placeColorMap[3];
  }
}
