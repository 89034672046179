import { IIndexable } from '@/types/pages';

export const SPORT_MAP: IIndexable = {
  HOCKEY: 'Хоккей',
  VOLLEYBALL_F: 'Волейбол (Д)',
  VOLLEYBALL_M: 'Волейбол (Ю)',
  FOOTBALL: 'Футбол',
  BASKETBALL_F: 'Баскетбол (Д)',
  BASKETBALL_M: 'Баскетбол (Ю)',
};

export const linkTypeMap: IIndexable = {
  '/hockey/': 'hockey',
  '/football/': 'football',
  '/basketball-m/': 'basketball-m',
  '/basketball-f/': 'basketball-f',
  '/volleyball-m/': 'volleyball-m',
  '/volleyball-f/': 'volleyball-f',
};
