import { render, staticRenderFns } from "./HockeyStat.vue?vue&type=template&id=fa1aef02&"
import script from "./HockeyStat.vue?vue&type=script&lang=ts&"
export * from "./HockeyStat.vue?vue&type=script&lang=ts&"
import style0 from "./HockeyStat.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports