









import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
  components: {},
})
export default class Banner extends Vue {
  @Prop() private img!: string;
  @Prop() private link!: string;
  @Prop() private big?: boolean;

  private get className() {
    if (this.big) {
      return 'b-support--first';
    }

    return 'b-support--second';
  }

  private getBgImg(img: string) {
    if (!img) {
      return '';
    }

    return `background-image: url(${img})`;
  }
}
